import { IReward, IRewardMap } from "../interfaces";
import { IRewardFilter } from "../interfaces/campaign-filter";
import { IStateMapGeneric } from "../interfaces/state-map";
import { IAction } from "../interfaces/store-action";

/* SET REWARDS */
export type ISetRewardsPayload = {
  rewardMap: IRewardMap;
  allRewardsLoaded?: boolean;
};
type ISetRewardsAction = IAction<"REWARD", "SET REWARDS", ISetRewardsPayload>;
export function setRewardsAction(
  payload: ISetRewardsPayload
): ISetRewardsAction {
  return {
    store: "REWARD",
    type: "SET REWARDS",
    payload,
  };
}

/* SET REWARD */
export type ISetRewardPayload = { id: string; reward: IReward };
type ISetRewardAction = IAction<"REWARD", "SET REWARD", ISetRewardPayload>;
export function setRewardAction(payload: ISetRewardPayload): ISetRewardAction {
  return {
    store: "REWARD",
    type: "SET REWARD",
    payload,
  };
}

/* HOOK ACTIONS */

/* LOAD REWARDS */
export type ILoadRewardsPayload = { filter?: IRewardFilter };
type ILoadRewardsAction = IAction<
  "REWARD",
  "LOAD REWARDS",
  ILoadRewardsPayload
>;
export function loadRewardsAction(
  payload: ILoadRewardsPayload
): ILoadRewardsAction {
  return {
    store: "REWARD",
    type: "LOAD REWARDS",
    payload,
  };
}

/* CONSUME REWARD */
export type IConsumeRewardPayload = {
  rewardUserId: string;
  params: {
    score?: number;
    timeLeft?: number;
    selectedSlotIndex?: number;
  };
};
type IConsumeRewardAction = IAction<
  "REWARD",
  "CONSUME REWARD",
  IConsumeRewardPayload,
  IStateMapGeneric<"params" | "rewardUserId">
>;
export function consumeRewardAction(
  payload: IConsumeRewardPayload
): IConsumeRewardAction {
  return {
    store: "REWARD",
    type: "CONSUME REWARD",
    payload,
  };
}

/* UN-CONSUME REWARDS */
export type IUnConsumeRewardPayload = { rewardUserId?: string };
type IUnConsumeRewardAction = IAction<
  "REWARD",
  "UNCONSUME REWARD",
  IUnConsumeRewardPayload
>;
export function unConsumeRewardAction(
  payload: IUnConsumeRewardPayload
): IUnConsumeRewardAction {
  return {
    store: "REWARD",
    type: "UNCONSUME REWARD",
    payload,
  };
}

/* RETRY REWARD */
export type IRetryRewardPayload = {
  rewardUserId: string;
  params: {
    score?: number;
    timeLeft?: number;
    selectedSlotIndex?: number;
  };
};
type IRetryRewardAction = IAction<
  "REWARD",
  "RETRY REWARD",
  IRetryRewardPayload,
  IStateMapGeneric<"params" | "rewardUserId">
>;
export function retryRewardAction(
  payload: IRetryRewardPayload
): IRetryRewardAction {
  return {
    store: "REWARD",
    type: "RETRY REWARD",
    payload,
  };
}

/* CONSUME GENERATE REWARD */
export type IConsumeGenerateRewardPayload = {
  rewardUserId: string;
  params: {
    score?: number;
    timeLeft?: number;
    selectedSlotIndex?: number;
  };
};
type IConsumeGenerateRewardAction = IAction<
  "REWARD",
  "CONSUME GENERATE REWARD",
  IConsumeGenerateRewardPayload,
  IStateMapGeneric<"params" | "rewardUserId">
>;
export function consumeGenerateRewardAction(
  payload: IConsumeGenerateRewardPayload
): IConsumeGenerateRewardAction {
  return {
    store: "REWARD",
    type: "CONSUME GENERATE REWARD",
    payload,
  };
}

/* LOCALLY CONSUME REWARD */
export type ILocallyConsumeRewardPayload = {
  rewardUserId: string;
  params: {
    score?: number;
    timeLeft?: number;
    selectedSlotIndex?: number;
  };
};
type ILocallyConsumeRewardAction = IAction<
  "REWARD",
  "LOCALLY CONSUME REWARD",
  ILocallyConsumeRewardPayload,
  IStateMapGeneric<"params" | "rewardUserId">
>;
export function locallyConsumeRewardAction(
  payload: ILocallyConsumeRewardPayload
): ILocallyConsumeRewardAction {
  return {
    store: "REWARD",
    type: "LOCALLY CONSUME REWARD",
    payload,
  };
}

/* REGISTER CONSUME REWARD */
export type IRegisterLocallyConsumedRewardPayload = {
  rewardUserId: string;
};
type IRegisterLocallyConsumedRewardAction = IAction<
  "REWARD",
  "REGISTER LOCALLY CONSUMED REWARD",
  IRegisterLocallyConsumedRewardPayload,
  IStateMapGeneric<"rewardUserId">
>;
export function registerLocallyConsumeRewardAction(
  payload: IRegisterLocallyConsumedRewardPayload
): IRegisterLocallyConsumedRewardAction {
  return {
    store: "REWARD",
    type: "REGISTER LOCALLY CONSUMED REWARD",
    payload,
  };
}

/* CONSUME SCORE BASED REWARD */
export type IConsumeScoreBasedRewardPayload = {
  rewardUserId: string;
  params: {
    score?: number;
  };
};

type IConsumeScoreBasedRewardAction = IAction<
  "REWARD",
  "CONSUME SCORE BASED REWARD",
  IConsumeScoreBasedRewardPayload,
  IStateMapGeneric<"params" | "rewardUserId">
>;
export function consumeScoreBasedRewardAction(
  payload: IConsumeScoreBasedRewardPayload
): IConsumeScoreBasedRewardAction {
  return {
    store: "REWARD",
    type: "CONSUME SCORE BASED REWARD",
    payload,
  };
}


export type IRewardAction =
  | ILoadRewardsAction
  | ISetRewardsAction
  | ISetRewardAction
  | IConsumeRewardAction
  | IUnConsumeRewardAction
  | IRetryRewardAction
  | IConsumeGenerateRewardAction
  | ILocallyConsumeRewardAction
  | IRegisterLocallyConsumedRewardAction
  | IConsumeScoreBasedRewardAction;
