import { IActivity, IBackendProgram, IProgram } from "../interfaces";
import { IBackendProgramLayout } from "../interfaces/fragment";
import {
  IReferralState,
  IReferralTier,
  IRewardLevel,
  ISelfReferral,
  ITier,
  IUserBReferral,
} from "../interfaces/referral";
import { withInternalError } from "./custom-errors";

const LEGACY_experienceTypeMatch = {
  streakv2: "streak_layout",
  streak: "streak_layout",
  gamechallenge: "game_challenge",
  multiStep: "multi_step_layout",
};

export function formatProgram(program: IBackendProgram): IProgram {
  let {
    stepsCompleted,
    activity,
    campaignId,
    status,
    experience,
    tier,
    userB,
    layout,
    accepted,
    checkAccepted,
    banner,
    programExpiry,
    interactionStatus
  } = program;

  let activities: IActivity[] =
    activity && activity.activities ? activity.activities : [];
  let programType:
    | "referral"
    | "onBording"
    | "quiz"
    | "multiStep"
    | "streak"
    | "tiered-referral"
    | "gamechallenge" | "streakv2" = null;
  let isExpired: boolean = false;
  let isCompleted: boolean = false;
  let referral: IReferralState;

  if (programExpiry) {
    isExpired = programExpiry.expired;
  }

  isCompleted = status === "completed" ? true : false;

  if (experience === "streakv2") {
    programType = "streakv2";
  }else if (experience === "streak") {
    programType = "streak";
  } else if (experience === "referral") {
    programType = "referral";
    referral = getReferralState(tier, userB);
  } else if (experience === "multistep") {
    programType = "multiStep";
  } else if (experience === "gamechallenge") {
    programType = "gamechallenge";
    if (activity && activity.activities && activity.activities.length) {
      activities = activity.activities.splice(1);
    }
  }

  if (!layout && program["fragmentMap"]) {
    const fragment = program["fragmentMap"]["fragments"]["program"];
    const newLayout: IBackendProgramLayout = {
      campaignId: campaignId,
      layout: null,
      programLayout: {
        type_id: null,
        challenge:
          fragment.programLayout[LEGACY_experienceTypeMatch[programType]],
        banner: fragment.bannerLayout,
        nudge: fragment["nudge"] || fragment.programLayout["nudge"],
      },
      analyticsMeta: {
        campaignName:
          program["fragmentMap"]["analyticsMeta"] &&
          program["fragmentMap"]["analyticsMeta"] &&
          program["fragmentMap"]["analyticsMeta"]["campaignName"],
      },
      reportingVersion: program["fragmentMap"]["reportingVersion"],
      theme: program["fragmentMap"]["theme"]

    };
    layout = { ...newLayout };
  }

  if (!layout) throw withInternalError("layout not found", "formatProgram", { program });

  return {
    campaignId: campaignId,
    campaignName: banner?.title,
    status: status,
    isCompleted,
    isExpired,
    isAccepted: checkAccepted ? accepted : true,
    expiry: programExpiry?.expiry ? programExpiry.expiry * 1000 : null,
    activity: {
      stepsCompleted,
      activities,
    },
    programType,
    referral,
    layout,
    interactionStatus
  };
}

function getReferralState(tier: ITier, userB: IUserBReferral) {
  const state: IReferralState = {
    allIds: [],
    currentId: 0,
    title: {},
    rule: {},
    level: {},
    levelReward: {},
    selfReferral: getSelf(userB),
    otherReferrals: userB.others || [],
  };

  const tierCount = tier.tiers.length;
  const tiers: IReferralTier[] = tier.tiers;

  for (let i = 0; i < tierCount; i++) {
    if (!tiers[i]) continue;
    if (tiers[i].current) {
      state.currentId = i;
      break;
    }
  }

  for (let i = 0; i < tierCount; i++) {
    if (!tiers[i]) continue;
    state.allIds.push(i);
    state.level[i] = getLevel(tiers[i], i, state.currentId);
  }
  return state;
}

export function getSelf(userB: IUserBReferral): ISelfReferral {
  if (!userB || !userB.self) return null;
  const self = userB.self;
  if (!self.valid) return null;
  const exp = new Date(self.expiresOn);
  const now = new Date();
  if (now.valueOf() >= exp.valueOf()) return null;
  let isCompleted = true;
  const activites = self.activity.activities;
  for (let i = 0; i < activites.length; i++) {
    if (activites[i].limits.campaign !== activites[i].completedCount.campaign) {
      isCompleted = false;
      break;
    }
  }
  return self;
}

export function getLevel(
  tier: IReferralTier,
  index: number,
  currentId: number
) {
  let level: IRewardLevel = {
    level: tier.level,
    cardImageSrc: "",
    body: "",
    totalSteps: tier.activity.activities[0].limits.campaign,
    completedSteps: tier.activity.activities[0].completedCount.campaign,
    subTitle: "",
  };

  if (index < currentId) level.subTitle = "";
  else if (index > currentId) level.subTitle = "";
  return level;
}
