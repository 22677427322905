import { IAction } from "../interfaces/store-action";
import { IUser } from "../interfaces/user";

type ISetUserAction = IAction<"USER", "USER_STORE SET USER", { user: IUser }>;
export const setUserAction = (payload: { user: IUser }): ISetUserAction => {
  return {
    store: "USER",
    type: "USER_STORE SET USER",
    payload,
  };
};

type IRequestUserAction = IAction<"USER", "USER_STORE REQUEST USER">;
export const requestUserAction = (): IRequestUserAction => {
  return {
    store: "USER",
    type: "USER_STORE REQUEST USER",
    payload: null,
  };
};

export type IUserAction = ISetUserAction | IRequestUserAction;
