export function replaceAll(
  target: string,
  search: string,
  replacement: string
) {
  return target.replace(new RegExp(escapeRegExp(search), "g"), replacement);
}

function escapeRegExp(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function replaceKeys(target: string, dict: { [key: string]: string }) {
  let result = target;
  for (const key in dict) {
    if (!target?.includes(key)) continue;
    result = replaceAll(result, key, dict[key]);
  }
  return result;
}