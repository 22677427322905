import { withInternalError } from "./custom-errors";
import { getVariableValue, IVariable } from "./state-variable-operation";

type IOperator = "GREATER_THAN" | "LESS_THAN" | "EQUAL_TO" | "NOT_EQUAL";
export interface ICondition {
  leftOperand: IVariable;
  rightOperand: IVariable;
  operator: IOperator;
}

export interface ICaseMap<T = any> {
  cases: {
    conditions: ICondition[];
    result: T;
  }[];
  defaultResult: T;
}

export const getConditionalResult = ({
  caseMap,
  state,
}: {
  caseMap: ICaseMap;
  state: any;
}) => {
  for (let i = 0; i < caseMap.cases.length; i++) {
    const singleCase = caseMap.cases[i];
    let didMatch = true;
    for (let j = 0; j < singleCase.conditions.length; j++) {
      const condition = singleCase.conditions[j];
      const leftValue = getVariableValue(state, condition.leftOperand);
      const rightValue = getVariableValue(state, condition.rightOperand);
      didMatch = checkCondition(leftValue, rightValue, condition.operator);
      if (!didMatch) {
        break;
      }
    }
    if (didMatch) {
      return singleCase.result;
    }
  }
  if (caseMap.defaultResult) {
    return caseMap.defaultResult;
  }
  return [];
};

export const pluckValue = (targetObject: any, properties: (string | string[])[]) => {
  if (!targetObject || !properties || !properties.length) {
    throw withInternalError("target object or properties is empty", "pluckValue", { targetObject, properties });
  }
  let r = targetObject;
  properties.forEach((p) => {
    if (r) {
      if (typeof p === "string") r = r[p];
      else if (typeof p === "object") {
        const nestedPropertyName = pluckValue(targetObject, p);
        r = r[nestedPropertyName];
      }
    } else {
      // throw withInternalError( `property "${p}" not found in target object: ${JSON.stringify(targetObject)}`, "pluckValue", { targetObject, properties });
    }
  });
  return r;
};

export const checkCondition = (
  leftValue: any,
  rightValue: any,
  operator: IOperator
) => {
  if (operator === "GREATER_THAN" && leftValue > rightValue) {
    return true;
  } else if (operator === "LESS_THAN" && leftValue < rightValue) {
    return true;
  } else if (operator === "EQUAL_TO" && leftValue == rightValue) {
    return true;
  } else if (operator === "NOT_EQUAL" && leftValue !== rightValue) {
    return true;
  }
  return false;
};
