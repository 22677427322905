import { useState } from "preact/hooks";
import { IStatus } from "../interfaces/status";

export const useStatus = (
  initStatus?: IStatus
): [IStatus, (status: IStatus) => void] => {
  const [status, setStatus] = useState<IStatus>(
    initStatus || { state: "IDEL" }
  );
  return [status, setStatus];
};
