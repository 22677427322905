let cache: IRouteQueryParams = null;
export const getRouteQueryParams = (): IRouteQueryParams => {
  if (cache) return cache;
  let queryParams = {};
  const queryString = window.location.search;

  queryString
    .slice(1)
    .split("&")
    .forEach((pair) => {
      const [key, value] = pair.split("=");
      queryParams[key] = decodeURIComponent(value);
    });

  cache = queryParams;
  return queryParams;
};

export interface IRouteQueryParams {
  [key: string]: string;
}

export const getRoutePath = (): string => {
  return window.location.pathname;
};
