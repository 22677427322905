import {
  IConsumeGenerateRewardPayload,
  IConsumeRewardPayload,
  ILocallyConsumeRewardPayload,
  IRegisterLocallyConsumedRewardPayload,
  IRetryRewardPayload,
  IRewardAction,
  IUnConsumeRewardPayload,
  setRewardAction,
  setRewardsAction,
} from "../actions/reward";
import { IRewardsState, RewardsContext } from "../contexts/reward";
import { IReward, IRewardMap } from "../interfaces";
import { IRewardFilter } from "../interfaces/campaign-filter";
import { IDispatch } from "../interfaces/dispatch";
import { IStatus } from "../interfaces/status";
import {
  fetchAllRewards,
  fetchPaginatedRewards,
  IConsumeRewardParams,
  redeemReward,
  rewardInit,
} from "../network/reward";
import { useSession } from "./session";
import { useContext, useEffect, useMemo, useRef, useState } from "preact/hooks";
import { useStatus } from "./status";
import { IActionDetails, IActionHandler } from "../interfaces/event-actions";
import { checkCondition } from "../util/conditional-operation";
import { getVariableValue } from "../util/state-variable-operation";
import { ISimpleGameResult } from "../interfaces/simple-game";
import { ILayoutRewardSlot } from "../interfaces/reward-layout-info";
import { useAutoRefresh } from "./auto-refresh";
import { withInternalError } from "../util/custom-errors";
import { useLogs } from "./logging";
import {
  deleteLocalStorageKey,
  getFromLocalStorage,
  setToLocalStorage,
} from "../util/localstorage";

export const useRewards = (): [IRewardsState, IDispatch<IRewardAction>] => {
  const context = useContext(RewardsContext);
  if (context === undefined) {
    throw withInternalError(
      "useRewards must be used within a RewardsProvider",
      "useRewards"
    );
  }
  return [context.state, context.dispatch];
};

export const useRewardsFilter = (
  filter?: IRewardFilter,
  ref?: any,
  useV1?: boolean
): [IRewardMap, IStatus, typeof refresh] => {
  const [rewardState, rewardDispatch] = useRewards();
  const [status, setStatus] = useStatus();
  const [recordError] = useLogs();

  useAutoRefresh([refresh]);

  useEffect(() => {
    if (rewardState.allRewardsLoaded) {
      setStatus({ state: "LOAD_SUCCESS" });
    } else {
      requestRewards();
    }
  }, [ref ? ref : filter]);

  const requestRewards = async (skipLoading?: boolean) => {
    try {
      if (!skipLoading) setStatus({ state: "LOADING" });

      let rewardMap: IRewardMap = null;

      if (useV1) {
        let campaignId = null;
        let rewardUserId = null;
        if (filter) {
          campaignId = getSingleIdFromFilter("campaignId", filter);
          rewardUserId = getSingleIdFromFilter("rewardUserId", filter);
        }
        const data = await fetchAllRewards(
          window["GluToken"],
          campaignId,
          rewardUserId
        );
        rewardMap = data;
      } else {
        const PaginatedRewardMap = await fetchPaginatedRewards(filter, 30, 1);
        rewardMap = PaginatedRewardMap.data.rewards;
      }
      rewardDispatch(
        setRewardsAction({
          rewardMap,
          allRewardsLoaded: filter ? false : true,
        })
      );

      setStatus({ state: "LOAD_SUCCESS" });
    } catch (e) {
      setStatus({ state: "LOAD_ERROR", message: "reward failed to load" });
      recordError(e);
    }
  };

  function refresh() {
    requestRewards(true);
  }

  const matched: IRewardMap = useMemo(() => {
    if (filter) return filterRewards(rewardState, filter);
    else return rewardState;
  }, [filter, rewardState.byId]);

  return [matched, status, refresh];
};

export const useSingleRewardFilter = (
  filter: IRewardFilter
): [IReward | null, IStatus] => {
  if (!filter)
    throw withInternalError("filter missing", "useSingleRewardFilter");
  const [rewardState, rewardDispatch] = useRewards();
  const [status, setStatus] = useStatus();
  const [recordError] = useLogs();

  useAutoRefresh([requestReward]);

  async function requestReward() {
    if (!filter) {
      throw new Error("filter missing");
    }

    setStatus({ state: "LOADING" });
    if (filter.campaignId && Object.keys(filter.campaignId).length > 0) {
      delete filter.rewardUserId;
    } else {
      delete filter.campaignId;
    }

    try {
      const PaginatedRewardMap = await fetchPaginatedRewards(filter, 1, 1);

      const {
        data: { rewards },
      } = PaginatedRewardMap;
      rewardDispatch(setRewardsAction({ rewardMap: rewards }));

      const reward = filterSingleReward(rewards, filter);
      if (reward) {
        setStatus({ state: "LOAD_SUCCESS" });
        return;
      }

      setStatus({ state: "LOAD_ERROR", message: "reward not found" });
    } catch (e) {
      setStatus({ state: "LOAD_ERROR", message: "error loading reward" });
      recordError(e);
    }
  }

  let reward = filterSingleReward(rewardState, filter);

  let rewardStatus: IStatus = status;

  if (reward) {
    reward = syncRewardWithLocalStorage(reward);
    rewardStatus = { state: "LOAD_SUCCESS" };
  } else if (status.state === "IDEL") {
    requestReward();
  }

  return [reward, rewardStatus];
};

export const didRewardMatch = (
  reward: IReward,
  filter: IRewardFilter
): boolean => {
  if (!filter) return true;
  if (!reward) throw withInternalError("reward missing", "didRewardMatch");

  if (filter.campaignId) {
    if (filter.campaignId[reward.campaignId as string]) {
    } else {
      if (filter.campaignId[reward.campaignId as string] == undefined) {
        if (!filter.campaignId?.default) {
          return false;
        }
      } else {
        return false;
      }
    }
  }
  if (
    filter.activityId &&
    !filter.activityId[reward.rewardProperties?.details?.activityId as string]
  )
    return false;
  if (
    filter.eventName &&
    !filter.eventName[reward.rewardProperties?.details?.eventName as string]
  )
    return false;
  if (filter.rewardType && !filter.rewardType[reward.type as string])
    return false;
  if (filter.rewardUserId && !filter.rewardUserId[reward.rewardUserId])
    return false;
  if (
    filter.userAId &&
    !filter.userAId[reward.rewardProperties?.details?.userAId as string]
  )
    return false;
  if (
    filter.userBId &&
    !filter.userBId[reward.rewardProperties?.details?.userBId as string]
  )
    return false;
  if (
    filter.experienceConsumed !== undefined &&
    reward.experienceConsumed !== filter.experienceConsumed
  )
    return false;
  if (filter.couponExpired !== undefined && reward.experienceConsumed) {
    if (reward.rewardProperties?.couponExpiry?.expiry) {
      const currentDate = Math.floor(new Date().valueOf() / 1000);
      if (filter.couponExpired === false) {
        if (currentDate > reward.rewardProperties.couponExpiry.expiry) {
          return false;
        }
      }
    }
  }

  if (
    filter.rewardExpired !== undefined &&
    filter.rewardExpired !== reward.rewardExpiry?.expired
  )
    return false;

  if (filter.removeNoRewardType) {
    if (
      (reward.experience === "direct" || reward.experienceConsumed) &&
      reward.type === "noReward"
    )
      return false;
  }
  if (filter.type) {
    let types = Object.keys(filter.type);
    for (let i = 0; i < types.length; i++) {
      if (filter.type[types[i]] == true) {
        if (reward.experience === types[i]) {
          return true;
        } else {
          return false;
        }
      }
      if (filter.type[types[i]] == false) {
        if (reward.experience === types[i]) {
          return false;
        }
      }
    }
  }

  if (filter.condition) {
    const condition = filter.condition;
    const leftValue = getVariableValue({ reward }, condition.leftOperand);
    const rightValue = getVariableValue({ reward }, condition.rightOperand);
    const didMatch = checkCondition(leftValue, rightValue, condition.operator);
    if (!didMatch) return false;
  }

  return true;
};

const filterSingleReward = (
  state: IRewardsState,
  filter: IRewardFilter
): IReward | null => {
  if (filter.rewardUserId) {
    const keys = Object.keys(filter.rewardUserId);
    if (keys.length === 1 && state.byId[keys[0]]) {
      return state.byId[keys[0]];
    }
  }
  for (let i = 0; i < state.allIds.length; i++) {
    const r = state.byId[state.allIds[i]];
    if (didRewardMatch(r, filter)) {
      return r;
    }
  }
  return null;
};

const filterRewards = (
  state: IRewardsState,
  filter: IRewardFilter
): IRewardMap => {
  const matched: IRewardMap = { allIds: [], byId: {} };
  if (!filter) return matched;
  for (let i = 0; i < state.allIds.length; i++) {
    const r = state.byId[state.allIds[i]];
    if (didRewardMatch(r, filter)) {
      matched.allIds.push(r.rewardUserId);
      matched.byId[r.rewardUserId] = r;
    }
  }
  return matched;
};

const getSingleIdFromFilter = (property: string, filter: IRewardFilter) => {
  const obj = filter[property] as any;
  if (!obj) return null;
  const keys = Object.keys(obj);
  if (keys.length !== 1) return null;
  return keys[0];
};

export const useRewardActionHandler = (
  actionHandler?: IActionHandler
): [IStatus, typeof onAction] => {
  const [session] = useSession();
  const [state, dispatch] = useRewards();
  const [status, setStatus] = useStatus();
  const [recordError] = useLogs();

  const requestConsumeReward = async (
    payload: IConsumeRewardPayload,
    onComplete?: Function,
    version: "v1.1" | "v2" = "v1.1"
  ) => {
    try {
      setStatus({ state: "LOADING" });
      const r = state.byId[payload.rewardUserId];
      const { newReward, params } = generateConsumeRewardParams(
        r,
        payload.params
      );
      newReward.selectedSlotIndex = params.selectedSlotIndex;
      newReward.status = "redeemable-seen";
      dispatch(
        setRewardAction({
          id: newReward.rewardUserId,
          reward: newReward,
        })
      );
      const seenReward = await redeemReward(
        newReward,
        params,
        session.token,
        version
      );
      dispatch(
        setRewardAction({
          id: seenReward.rewardUserId,
          reward: seenReward,
        })
      );
      setStatus({ state: "LOAD_SUCCESS" });
      if (actionHandler)
        actionHandler({} as any, {
          analytics: { eventName: "GAME_PLAYED", type: "TRACK" },
          state: { reward: seenReward },
          onComplete: null,
        });
      if (onComplete) {
        onComplete();
      }
    } catch (e) {
      setStatus({ state: "LOAD_ERROR", message: "failed to consume reward" });
      recordError(e);
    }
  };

  const requestRetryReward = async (
    payload: IRetryRewardPayload,
    onComplete: Function
  ) => {
    try {
      setStatus({ state: "LOADING" });
      const r = state.byId[payload.rewardUserId];
      const { newReward, params } = generateRetryRewardParams(r);
      newReward.selectedSlotIndex = params.selectedSlotIndex;
      await redeemReward(newReward, params, session.token);
      const filter: IRewardFilter = {
        rewardUserId: {},
      };
      if (newReward.rewardUserId) {
        filter.rewardUserId[newReward.rewardUserId] = true;
      }
      const paginatedRewardMap = await fetchPaginatedRewards(filter, 1, 1);
      const rewardMap = paginatedRewardMap.data.rewards;
      dispatch(setRewardsAction({ rewardMap }));
      if (onComplete) {
        onComplete();
      }
      setStatus({ state: "LOAD_SUCCESS" });
    } catch (e) {
      setStatus({ state: "LOAD_ERROR", message: "failed to retry" });
      recordError(e);
    }
  };

  const requestGenerateConsumeReward = async (
    payload: IConsumeGenerateRewardPayload,
    onComplete?: Function
  ) => {
    try {
      setStatus({ state: "LOADING" });
      const r = state.byId[payload.rewardUserId];

      const { newReward, params } = generateConsumeGenerateRewardParams(
        r,
        payload.params
      );
      newReward.selectedSlotIndex = params.selectedSlotIndex;
      await redeemReward(newReward, params, session.token);
      const filter: IRewardFilter = {
        rewardUserId: {},
      };
      if (newReward.rewardUserId) {
        filter.rewardUserId[newReward.rewardUserId] = true;
      }
      const paginatedRewardMap = await fetchPaginatedRewards(filter, 1, 1);
      const rewardMap = paginatedRewardMap.data.rewards;
      dispatch(setRewardsAction({ rewardMap }));
      setStatus({ state: "LOAD_SUCCESS" });
      if (actionHandler)
        actionHandler({} as any, {
          analytics: { eventName: "GAME_PLAYED", type: "TRACK" },
          state: {
            reward: rewardMap.byId[newReward.rewardUserId],
            onComplete: null,
          },
        });
      if (onComplete) {
        onComplete();
      }
    } catch (e) {
      console.log(e);
      setStatus({
        state: "LOAD_ERROR",
        message: "failed to consume the reward",
      });
      recordError(e);
    }
  };

  const requestSimpleRetryReward = async (
    payload: IUnConsumeRewardPayload,
    details?: IActionDetails
  ) => {
    try {
      setStatus({ state: "LOADING" });
      let id = null;
      if (payload && payload.rewardUserId) {
        id = payload.rewardUserId;
      } else if (details?.state?.reward?.rewardUserId) {
        id = details?.state?.reward?.rewardUserId;
      } else {
        throw withInternalError(
          "reward user id not passed",
          "useRewardActionHandler.requestSimpleRetryReward"
        );
      }
      const r = state.byId[id];
      const { newReward, params } = generateRetryRewardParams(r);
      newReward.selectedSlotIndex = params.selectedSlotIndex;
      await redeemReward(newReward, params, session.token);
      const filter: IRewardFilter = {
        rewardUserId: {},
      };
      if (newReward.rewardUserId) {
        filter.rewardUserId[newReward.rewardUserId] = true;
      }
      const paginatedRewardMap = await fetchPaginatedRewards(filter, 1, 1);
      const rewardMap = paginatedRewardMap.data.rewards;
      if (
        rewardMap &&
        rewardMap.byId &&
        rewardMap.byId[id] &&
        (rewardMap.byId[id].experience === "quiz" ||
          rewardMap.byId[id].experience === "unitygame")
      ) {
        rewardMap.byId[id].experienceConsumed = false;
      }
      dispatch(setRewardsAction({ rewardMap }));
      if (details?.onComplete) {
        details?.onComplete();
      }
      setStatus({ state: "LOAD_SUCCESS" });
    } catch (e) {
      setStatus({ state: "LOAD_ERROR", message: "failed to retry" });
      recordError(e);
    }
  };

  const locallyConsumeReward = (
    payload: ILocallyConsumeRewardPayload,
    onComplete?: Function
  ) => {
    try {
      setStatus({ state: "LOADING" });
      const r = state.byId[payload.rewardUserId];
      const { params } = generateConsumeRewardParams(r, payload.params);
      const newReward: IReward = {
        ...r,
        locallyConsumedData: {
          params,
        },
      };
      dispatch(
        setRewardAction({
          id: newReward.rewardUserId,
          reward: newReward,
        })
      );
      setToLocalStorage(
        `__GLU__LOCAL_${r.rewardUserId}`,
        newReward.locallyConsumedData
      );
      setStatus({ state: "LOAD_SUCCESS" });
      if (onComplete) {
        onComplete();
      }
    } catch (e) {
      setStatus({ state: "LOAD_ERROR", message: "failed to consume reward" });
      recordError(e);
    }
  };

  const registerLocallyConsumedReward = async (
    payload: IRegisterLocallyConsumedRewardPayload,
    onComplete?: Function
  ) => {
    try {
      const r = state.byId[payload.rewardUserId];
      if (r.status === "redeemable-seen" || !r.locallyConsumedData?.params)
        return;

      setStatus({ state: "LOADING" });
      const params = r.locallyConsumedData.params;
      const newReward: IReward = {
        ...r,
        experienceConsumed: true,
        seenStatus: "CONSUMING",
      };
      newReward.selectedSlotIndex = params.selectedSlotIndex;
      newReward.status = "redeemable-seen";
      dispatch(
        setRewardAction({
          id: newReward.rewardUserId,
          reward: newReward,
        })
      );
      const seenReward = await redeemReward(newReward, params, session.token);
      dispatch(
        setRewardAction({
          id: seenReward.rewardUserId,
          reward: seenReward,
        })
      );
      setStatus({ state: "LOAD_SUCCESS" });
      deleteLocalStorageKey(`__GLU__LOCAL_${r.rewardUserId}`);
      if (actionHandler)
        actionHandler({} as any, {
          analytics: { eventName: "GAME_PLAYED", type: "TRACK" },
          state: { reward: seenReward },
          onComplete: null,
        });
      if (onComplete) {
        onComplete();
      }
    } catch (e) {
      setStatus({ state: "LOAD_ERROR", message: "failed to consume reward" });
      recordError(e);
    }
  };

  const onAction: IActionHandler = (action, details) => {
    if (action.store === "REWARD") {
      if (action.type === "CONSUME REWARD") {
        requestConsumeReward(action.payload, details?.onComplete);
      } else if (action.type === "CONSUME GENERATE REWARD") {
        requestGenerateConsumeReward(action.payload, details?.onComplete);
      } else if (action.type === "RETRY REWARD") {
        requestRetryReward(action.payload, details?.onComplete);
      } else if (action.type === "UNCONSUME REWARD") {
        requestSimpleRetryReward(action.payload, details);
      } else if (action.type === "LOCALLY CONSUME REWARD") {
        locallyConsumeReward(action.payload, details?.onComplete);
      } else if (action.type === "REGISTER LOCALLY CONSUMED REWARD") {
        registerLocallyConsumedReward(action.payload, details.onComplete);
      } else if (action.type === "CONSUME SCORE BASED REWARD") {
        requestConsumeReward(action.payload, details?.onComplete, "v2");
      }
    }
  };

  return [status, onAction];
};

/* util, consume reward params generator */
const generateConsumeRewardParams = (
  reward: IReward,
  result: ISimpleGameResult
): { newReward: IReward; params: IConsumeRewardParams } => {
  const newReward: IReward = {
    ...reward,
    experienceConsumed: true,
    seenStatus: "CONSUMING",
  };
  const params: IConsumeRewardParams = {
    selectedSlotIndex: reward.selectedSlotIndex,
    generateNewReward: true,
    rewardUserId: newReward.rewardUserId,
  };

  if (result) {
    if (Number.isInteger(result.rewardIndex)) {
      params.selectedSlotIndex = result.rewardIndex;
      reward.selectedSlotIndex = params.selectedSlotIndex;
    }
    if (Number.isInteger(result.score)) {
      params.score = result.score;
      const i = getSelectedRewardIndexFromScore(
        reward.layout.layout.data.slots,
        result.score
      );
      if (Number.isInteger(i)) {
        params.selectedSlotIndex = i;
        reward.selectedSlotIndex = i;
      }
      if (newReward.quiz) {
        newReward.quiz.score = params.score;
      }
    }
    if (
      result.result &&
      (!Number.isInteger(params.selectedSlotIndex) ||
        params.selectedSlotIndex < 0)
    ) {
      params.selectedSlotIndex = result.result === "WIN" ? 1 : 0;
      reward.selectedSlotIndex = params.selectedSlotIndex;
    }
  }

  return { newReward, params };
};

const generateRetryRewardParams = (
  reward: IReward
): { newReward: IReward; params: IConsumeRewardParams } => {
  const params: IConsumeRewardParams = {
    refreshReward: true,
    selectedSlotIndex: reward.selectedSlotIndex,
    rewardUserId: reward.rewardUserId,
  };
  const newReward: IReward = { ...reward };

  return { newReward, params };
};

const generateConsumeGenerateRewardParams = (
  reward: IReward,
  result: ISimpleGameResult
): { newReward: IReward; params: IConsumeRewardParams } => {
  const newReward: IReward = {
    ...reward,
    experienceConsumed: true,
    seenStatus: "CONSUMING",
  };
  const params: IConsumeRewardParams = {
    selectedSlotIndex: reward.selectedSlotIndex,
    rewardUserId: newReward.rewardUserId,
    createNewReward: true,
  };

  if (result) {
    if (Number.isInteger(result.rewardIndex)) {
      params.selectedSlotIndex = result.rewardIndex;
    }
    if (Number.isInteger(result.score)) {
      params.score = result.score;
      const i = getSelectedRewardIndexFromScore(
        reward.layout.layout.data.slots,
        result.score
      );
      if (Number.isInteger(i)) {
        params.selectedSlotIndex = i;
      }
      if (newReward.quiz) {
        newReward.quiz.score = params.score;
      }
    }
    if (
      result.result &&
      (!Number.isInteger(params.selectedSlotIndex) ||
        params.selectedSlotIndex < 0)
    ) {
      params.selectedSlotIndex = result.result === "WIN" ? 1 : 0;
    }
  }

  return { newReward, params };
};

function getSelectedRewardIndexFromScore(
  slots: ILayoutRewardSlot[],
  score: number
) {
  let selectedSlotIndex: number = 0;
  for (let i = 0; i < slots.length; i++) {
    if (score <= slots[i].maxscore) {
      selectedSlotIndex = i;
      break;
    }
  }
  return selectedSlotIndex;
}

const syncRewardWithLocalStorage = (reward: IReward) => {
  try {
    if (
      reward.rewardUserId &&
      reward.experienceConsumed === false &&
      reward.locallyConsumedData !== null
    ) {
      const newReward: IReward = { ...reward };
      let localData = reward.locallyConsumedData;
      if (!localData) {
        localData = getFromLocalStorage(
          `__GLU__LOCAL_${reward.rewardUserId}`
        ) as {
          params: IConsumeRewardParams;
        };
      }
      if (localData && localData.params) {
        newReward.experienceConsumed = true;
        newReward.selectedSlotIndex = localData.params.selectedSlotIndex;
        if (reward.quiz) reward.quiz.score = localData.params.score;
      } else {
        newReward.locallyConsumedData = null;
      }
      reward = newReward;
    }
  } catch (e) {}
  return reward;
};

export const useRewardInit = (reward: IReward): [IStatus] => {
  const [session] = useSession();
  const [status, setStatus] = useStatus();
  const [rewardState, rewardDispatch] = useRewards();

  useEffect(() => {
    if (reward && reward.isSkeleton) {
      requestRewardInitObject();
    }
  }, [reward?.isSkeleton]);

  const requestRewardInitObject = async () => {
    try {
      setStatus({ state: "LOADING" });
      if (!session.token) {
        throw new Error("Token not found");
      }
      const rewardObject = await rewardInit(
        session.token,
        reward.campaignId,
        reward.rewardUserId
      );
      if (rewardObject) {
        let newRewardMap: IRewardMap = {
          ...rewardState,
          allIds: [...rewardState.allIds, reward?.rewardUserId],
          byId: {
            ...rewardState.byId,
            [reward?.rewardUserId]: {
              ...rewardObject,
              layout: reward.layout,
              fragmentMap: reward.fragmentMap,
            },
          },
        };
        rewardDispatch(setRewardsAction({ rewardMap: newRewardMap }));
        setStatus({ state: "LOAD_SUCCESS" });
      } else {
        setStatus({ state: "LOAD_ERROR" });
      }
    } catch (e) {
      setStatus({ state: "LOAD_ERROR", message: "Reward not Initialized" });
    }
  };

  return [status];
};

export const usePaginatedRewardsFilter = (
  filter: IRewardFilter,
  limit: number,
  sort?: {
    on: string;
    order: 1 | -1;
  }
): [IRewardPaginationState, IActionHandler] => {
  const [status, setStatus] = useStatus();
  const [rewardState, rewardDispatch] = useRewards();
  const [totalRewardCount, setTotalRewardCount] = useState<number>();
  const [totalPages, setTotalPages] = useState<number>();
  const [hideLoader, setHideLoader] = useState<boolean>(false);
  const currentPage = useRef<number>(0);
  const rewardUserIdsRef = useRef<string[]>([]);
  useAutoRefresh([refresh]);

  useEffect(() => {
    requestRewards();
  }, []);

  const updateFilters = () => {
    let updatedFilters = { ...filter };

    if (!updatedFilters.hasOwnProperty("rewardExpired")) {
      updatedFilters = { ...updatedFilters, rewardExpired: false };
    }
    if (updatedFilters["rewardExpired"] === null) {
      delete updatedFilters["rewardExpired"];
    }

    if (!updatedFilters.hasOwnProperty("couponExpired")) {
      updatedFilters = { ...updatedFilters, couponExpired: false };
    }

    if (updatedFilters["couponExpired"] === null) {
      delete updatedFilters["couponExpired"];
    }

    if (!updatedFilters.hasOwnProperty("removeNoRewardType")) {
      updatedFilters = { ...updatedFilters, removeNoRewardType: true };
    }

    return updatedFilters;
  };

  const rewardfilters = useMemo(() => updateFilters(), [filter]);

  const requestRewards = async (skipLoading?: boolean) => {
    try {
      if (!skipLoading) {
        setStatus({ state: "LOADING" });
      }

      const newPage = currentPage.current + 1;
      const response: IPagination = await fetchPaginatedRewards(
        rewardfilters,
        skipLoading ? limit * currentPage.current : limit,
        skipLoading ? 1 : newPage,
        sort
      );
      setStatus({ state: "LOAD_SUCCESS" });

      const newRewardUserIds = response.data.rewards.allIds;
      if (!skipLoading) {
        rewardUserIdsRef.current = [
          ...rewardUserIdsRef.current,
          ...newRewardUserIds,
        ];
      } else {
        rewardUserIdsRef.current = [...newRewardUserIds];
      }

      rewardDispatch(
        setRewardsAction({
          rewardMap: response.data.rewards,
          allRewardsLoaded:
            newPage >= response.totalPages && !filter ? true : false,
        })
      );

      setStatus({ state: "LOAD_SUCCESS" });
      if (!skipLoading) currentPage.current = newPage;
      setTotalPages(response.totalPages);
      setTotalRewardCount(response.totalItems);
      if (newPage >= response.totalPages) {
        setHideLoader(true);
      }
    } catch (e) {
      console.log(e);
      setStatus({ state: "LOAD_ERROR", message: "reward failed to load" });
    }
  };

  const onAction: IActionHandler = (action, details) => {
    if (action.store !== "PAGINATION_ACTION") return;
    if (action.type === "LOAD_MORE") {
      if (totalPages >= currentPage.current) {
        if (status.state === "LOADING" || hideLoader) return;
        requestRewards();
      }
    }
  };

  function refresh() {
    requestRewards(true);
  }

  const rewardList = useMemo(() => {
    const matched: IRewardMap = { allIds: [], byId: {} };
    const titleCache: { [k: string]: boolean } = {};
    for (let i = 0; i < rewardUserIdsRef.current.length; i++) {
      const rewardUserId = rewardUserIdsRef.current[i];
      if (rewardState.byId[rewardUserId]) {
        const reward = rewardState.byId[rewardUserId];
        if (filter?.skipDuplicates && reward.experienceConsumed) {
          const key = reward.rewardProperties.title + reward.campaignId;
          if (titleCache[key]) continue;
          titleCache[key] = true;
        }
        matched.allIds.push(reward.rewardUserId);
        matched.byId[reward.rewardUserId] = reward;
      }
    }

    return matched;
  }, [rewardUserIdsRef.current, rewardState]);

  return [
    {
      rewardMap: rewardList,
      status: status,
      totalRewardCount: totalRewardCount,
      hideLoader: hideLoader,
    },
    onAction,
  ];
};

interface IPagination {
  totalPages: number;
  page: number;
  totalItems: number;
  data: {
    rewards: IRewardMap;
  };
  limit: number;
}

export interface IRewardPaginationState {
  rewardMap: IRewardMap;
  status: IStatus;
  totalRewardCount: number;
  hideLoader: boolean;
}
