import { IFontConfig, IThemeConfig } from "../interfaces/client-configurations";

export function applyThemeConfig(themeData: IThemeConfig) {
  let variablesMap = themeData.variables;
  var allVariables = "";
  const head = document.getElementsByTagName("head")[0];

  if (themeData && themeData.fonts) {
    themeData.fonts.map((value) => {
      let link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = value.url;
      head.append(link);
      allVariables += `${value.type} : ${value.family + ", " + value.fallback};`;
      allVariables += `${value.type} : "${value.family}", ${value.fallback};`;
    });
  }

  for (const [key, value] of Object.entries(variablesMap)) {
    allVariables += `${key} : ${value};`;
  }

  var styles = `:root { ${allVariables} }`;
  var css = document.createElement("style");
  css.appendChild(document.createTextNode(styles));
  head.appendChild(css);
}

const loadedFonts = {};

export const loadGoogleFonts = (
  fonts: IFontConfig[],
  head: HTMLHeadElement
) => {
  fonts.forEach((value) => {
    if (loadedFonts[value.url]) return;
    let link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = value.url;
    head.append(link);
    loadedFonts[value.url] = true;
  });
};