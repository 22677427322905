import { ISessionAction } from "../actions/session";
import { IContext } from "../interfaces/store-action";
import { IRouteQueryParams } from "../util/routes-details";
import { createContext, h } from "preact";
import { useMemo, useReducer } from "preact/hooks";
import { nanoid } from "nanoid";

export interface ISessionState {
  queryParams: IRouteQueryParams;
  token: string;
  id?: string;
  session_start_time: Date;
}

const initialState: ISessionState = {
  token: null,
  queryParams: {},
  id: nanoid(),
  session_start_time: new Date()
};

function sessionReducer(
  state: ISessionState,
  action: ISessionAction
): ISessionState {
  switch (action.type) {
    case "SESSION_STORE SET SESSION": {
      return action.payload;
    }
    case "SESSION_STORE SET QUERY PARAM SESSION": {
      return {
        ...state,
        queryParams: action.payload.queryParams,
      };
    }
    case "SESSION_STORE SET TOKEN": {
      window["token"] = action.payload.token;
      window["GluToken"] = action.payload.token;
      return {
        ...state,
        token: action.payload.token,
      };
    }
  }
}

export const SessionContext =
  createContext<IContext<ISessionState, ISessionAction>>(undefined);

export function SessionProvider(props: { children?: any }) {
  const [state, dispatch] = useReducer<ISessionState, ISessionAction>(
    sessionReducer,
    initialState
  );

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <SessionContext.Provider value={value}>
      {props.children}
    </SessionContext.Provider>
  );
}
