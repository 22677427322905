import { getRouteQueryParams } from "./routes-details";

//#region getDeviceType
type IDeviceType = "mobile" | "tablet" | "desktop";

/* check mobile */
const isMobileDevice = (userAgent: string): boolean => {
  const regexs = [
    /(Android)(.+)(Mobile)/i,
    /BlackBerry/i,
    /iPhone|iPod/i,
    /Opera Mini/i,
    /IEMobile/i,
  ];
  return regexs.some((b) => userAgent.match(b));
};

/* check tablet */
const isTabletDevice = (userAgent: string): boolean => {
  const regex =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;
  return regex.test(userAgent.toLowerCase());
};

/* main export */
export function getDeviceType(): IDeviceType {
  const userAgent: string = navigator.userAgent || navigator.vendor;
  let device: IDeviceType = "desktop";

  if (isMobileDevice(userAgent)) {
    device = "mobile";
  }
  if (isTabletDevice(userAgent)) {
    device = "tablet";
  }
  return device;
}
//#endregion

//#region getOs
type IDeviceOS =
  | "windows_phone_os"
  | "android"
  | "ios"
  | "windows"
  | "macos"
  | "unix"
  | "linux"
  | "unknown";

export function getDeviceOS(): IDeviceOS {
  const userAgent: string = navigator.userAgent || navigator.vendor;
  if (/windows phone/i.test(userAgent)) return "windows_phone_os";
  else if (/android/i.test(userAgent)) return "android";
  else if (/iPad|iPhone|iPod/.test(userAgent) && !window["MSStream"])
    return "ios";
  else if (userAgent.indexOf("Win") !== -1) return "windows";
  else if (userAgent.indexOf("Mac") !== -1) return "macos";
  else if (userAgent.indexOf("X11") !== -1) return "unix";
  else if (userAgent.indexOf("Linux") !== -1) return "linux";

  return "unknown";
}
//#endregion

//#region  getAgentType
const rules = [
  // if it says it's a webview, let's go with that
  "WebView",
  // iOS webview will be the same as safari but missing "Safari"
  "(iPhone|iPod|iPad)(?!.*Safari)",
  // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
  // Android KitKat to lollipop webview will put {version}.0.0.0
  "Android.*(wv|.0.0.0)",
  // old chrome android webview agent
  "Linux; U; Android",
];

type IAgentType = "iframe" | "webview" | "browser";

export function getAgentType(): IAgentType {
  if (window && window.parent !== window) return "iframe";

  const userAgent: string = navigator.userAgent || navigator.vendor;
  const webviewRegExp = new RegExp("(" + rules.join("|") + ")", "ig");

  if (userAgent.match(webviewRegExp)) return "webview";

  return "browser";
}
//#endregion

//#region getPlatform
type IAppPlatform =
  | "android"
  | "ios"
  | "flutter"
  | "reactNative"
  | "web"
  | string;

export function getAppPlatform(): IAppPlatform {
  const queryParams = getRouteQueryParams();
  if (queryParams.platform) {
    return queryParams.platform;
  } else {
    try {
      if (window["app"] && window["app"].postMessage) {
        return "flutter";
      } else if (window["app"] && window["app"].callback) {
        return "android";
      } else if (
        window["ReactNativeWebView"] &&
        window["ReactNativeWebView"].postMessage
      ) {
        return "reactNative";
      } else if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.callback.postMessage
      ) {
        return "ios";
      } else {
        return "web";
      }
    } catch (e) {
      console.log(e);
    }
  }
}
//#endregion

export interface IEnvironmentDetails {
  deviceType: IDeviceType;
  os: IDeviceOS;
  agentType: IAgentType;
  appPlatform: IAppPlatform;
}

let environmentDetails: IEnvironmentDetails;

export function getEnvironmentDetails(): IEnvironmentDetails {
  if (environmentDetails) {
    return environmentDetails;
  } else {
    environmentDetails = {
      deviceType: getDeviceType(),
      os: getDeviceOS(),
      agentType: getAgentType(),
      appPlatform: getAppPlatform(),
    };
    return environmentDetails;
  }
}
