import { IRewardAction } from "../actions/reward";
import { IReward, IRewardMap } from "../interfaces";
import { IContext } from "../interfaces/store-action";
import { createContext, h } from "preact";
import { useMemo, useReducer } from "preact/hooks";

export interface IRewardsState {
  allIds: string[];
  byId: {
    [id: string]: IReward;
  };
  allRewardsLoaded?: boolean;
}
interface ISkeletonObjects {[campaignId:string]:boolean}

let rewardsSkeletonObject: ISkeletonObjects = {};
const initialState: IRewardsState = {
  allIds: [],
  byId: {},
  allRewardsLoaded: false,
};

function rewardsReducer(
  state: IRewardsState,
  action: IRewardAction
): IRewardsState {
  switch (action.type) {
    case "SET REWARDS": {
      const newState: IRewardsState = {
        ...state,
        allIds: [...state.allIds],
        byId: { ...state.byId },
      };
      const newRewardMap = action.payload.rewardMap;
      newRewardMap.allIds.forEach((id) => {
        const r  = newRewardMap.byId[id];
        if(rewardsSkeletonObject[r.campaignId] && r.isSkeleton){
          return;
        } 
        
        if (!newState.byId[id]) {
          newState.allIds.push(id);
          rewardsSkeletonObject = {
            ...rewardsSkeletonObject,
            [r.campaignId]:true
          }
        }
        newState.byId[id] = newRewardMap.byId[id];
       
      });
      newState.allRewardsLoaded = action.payload.allRewardsLoaded;
      return newState;
    }
    case "SET REWARD": {
      const newState: IRewardsState = {
        ...state,
        byId: { ...state.byId },
      };

      if (!state.byId[action.payload.id]) {
        newState.allIds = [...newState.allIds, action.payload.id];
      }

      newState.byId[action.payload.id] = action.payload.reward;
      return newState;
    }
  }
}

export const RewardsContext =
  createContext<IContext<IRewardsState, IRewardAction>>(undefined);

export function RewardsProvider(props: { children?: any }) {
  const [state, dispatch] = useReducer<IRewardsState, IRewardAction>(
    rewardsReducer,
    initialState
  );

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <RewardsContext.Provider value={value}>
      {props.children}
    </RewardsContext.Provider>
  );
}
