import { ISessionState } from "../contexts/session";
import { IAction } from "../interfaces/store-action";
import { IRouteQueryParams } from "../util/routes-details";

type ISetSession = IAction<
  "SESSION",
  "SESSION_STORE SET SESSION",
  ISessionState
>;

export const setSession = (payload: ISessionState): ISetSession => {
  return {
    store: "SESSION",
    type: "SESSION_STORE SET SESSION",
    payload,
  };
};

type ISetQueryParamSession = IAction<
  "SESSION",
  "SESSION_STORE SET QUERY PARAM SESSION",
  { queryParams: IRouteQueryParams }
>;
export const setQueryParamSession = (payload: {
  queryParams: IRouteQueryParams;
}): ISetQueryParamSession => {
  return {
    store: "SESSION",
    type: "SESSION_STORE SET QUERY PARAM SESSION",
    payload,
  };
};

type IRequestToken = IAction<
  "SESSION",
  "SESSION_STORE REQUEST TOKEN",
  { details: {} }
>;
export const requestToken = (payload: { details: {} }): IRequestToken => {
  return {
    store: "SESSION",
    type: "SESSION_STORE REQUEST TOKEN",
    payload,
  };
};

type ISetToken = IAction<
  "SESSION",
  "SESSION_STORE SET TOKEN",
  { token: string }
>;
export const setToken = (payload: { token: string }): ISetToken => {
  return {
    store: "SESSION",
    type: "SESSION_STORE SET TOKEN",
    payload,
  };
};

export type ISessionAction =
  | ISetSession
  | ISetQueryParamSession
  | IRequestToken
  | ISetToken;
