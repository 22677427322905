import { useContext, useEffect } from "preact/hooks";
import {
  IClientConfigurationsAction,
  updateClientConfigurations,
} from "../actions/client-configurations";
import {
  ClientConfigurationContext,
  IClientConfigurationState,
} from "../contexts/client-configurations";
import { IAllWalletFragments, IClientFragments } from "../interfaces/client-level-fragments";
import { IDispatch } from "../interfaces/dispatch";
import { IStatus } from "../interfaces/status";
import { fetchConfigurations } from "../network/client-configurations";
import { withInternalError } from "../util/custom-errors";
import { getFromLocalStorage, setToLocalStorage } from "../util/localstorage";
import { useLogs } from "./logging";
import { useSession } from "./session";
import { useStatus } from "./status";

export function useClientConfigurations(): [
  IClientConfigurationState,
  IDispatch<IClientConfigurationsAction>
] {
  const context = useContext(ClientConfigurationContext);
  if (context === undefined) {
    throw withInternalError("initialise the client configuration context provoder", "useClientConfigurations");
  }
  return [context.state, context.dispatch];
}

export const useClientConfigurationsInitilizer = (): IStatus => {
  const [status, setStatus] = useStatus({ state: "LOADING" });
  const [session] = useSession();
  const [configState, configDispatch] = useClientConfigurations();
  const [recordError] = useLogs();

  useEffect(() => {
    if (session.token) {
      const clientId = getClientFromToken(session.token);
      window["GLU_CLIENT_ID"] = clientId;
      load(clientId);
    }
  }, [session.token]);

  const load = async (clientId?: string) => {
    try {
      const key = clientId ? "__GLU__CLIENT_CONFIG_" + clientId : null;
      let localConfig: IClientConfigurationState = getFromLocalStorage(key);
      if (localConfig) {
        configDispatch(updateClientConfigurations(localConfig));
        setStatus({ state: "LOAD_SUCCESS" });
        try {
          setTimeout(async () => {
            const networkConfig = await fetchConfigurations(session.token, [
              "wallet",
              "client",
              "analytics",
              "error",
              "loading",
            ]);
            if (networkConfig) {
              configDispatch(updateClientConfigurations(networkConfig));
              setToLocalStorage(key, networkConfig, 48);
            }
          }, 3000);
        } catch (e) {}
      } else {
        const networkConfig = await fetchConfigurations(session.token, [
          "wallet",
          "client",
          "analytics",
          "error",
          "loading",
        ]);
        if (networkConfig) {
          configDispatch(updateClientConfigurations(networkConfig));
          setToLocalStorage(key, networkConfig, 48);
          setStatus({ state: "LOAD_SUCCESS" });
        } else {
          throw withInternalError("client configurations not found", "useClientConfigurationsInitilizer.load");
        }
      }
    } catch (e) {
      setStatus({
        state: "LOAD_ERROR",
        message: "failed to fetch client config",
      });
      recordError(e);
    }
  };

  return status;
};

export const useWalletConfig = (): [IAllWalletFragments] => {
  const [state] = useClientConfigurations();
  return [state.wallet];
};
export const useBackButtonConfig = (): [IClientFragments]=> {
  const [state] = useClientConfigurations();
  return [state?.client?.backButton];
};

export const getClientFromToken = (token: string): string => {
  try {
    const payloadEncoded = token.split(".")[1];
    const decodedPayload = atob(payloadEncoded);
    const payload = JSON.parse(decodedPayload);
    return payload["client"];
  } catch (e) {
    console.log(e);
    return null;
  }
}