import {
  IUserAttributes,
  IUserIdentification,
  IUserRegistrationData,
} from "../interfaces/user-registration";
import { get, post } from "../util/api";
import { nanoid } from "nanoid";
import { withMalformedApiResponseError } from "../util/custom-errors";

/* HELPER */
const fetchUserId = (): string => {
  if (!window.localStorage) return null;
  const session = localStorage.getItem("__glu__session");
  if (session) return session;
};

/* HELPER */
const generateUserId = (): string => {
  if (!window.localStorage) return null;
  const session = localStorage.getItem("__glu__session");
  if (session) return session;
  else {
    const id = nanoid();
    localStorage.setItem("__glu__session", id);
    return id;
  }
};

/* HELPER */
const fetchToken = async (
  writeKey: string,
  identification: IUserIdentification,
  attributes: IUserAttributes
): Promise<string> => {
  let userId = identification.userId;
  let anonymousId = identification.anonymousId;
  let userState: "loggedIn" | "nonLoggedIn" = identification.userId
    ? "loggedIn"
    : "nonLoggedIn";

  if (!anonymousId) {
    anonymousId = fetchUserId();
  }

  if (!userId) {
    if (!anonymousId) {
      anonymousId = generateUserId();
    }
    userId = anonymousId;
  }

  const reqBody = {
    writeKey,
    userId,
    userState,
    customAttributes: attributes.customAttributes,
    ...attributes.gluAttributes,
  };

  if (anonymousId) {
    reqBody.anonymousId = anonymousId;
  }

  const res = await post<{ data: { token: string } }>(
    `${process.env.HOST_ENV}/user/v1/user/sdk?token=true`,
    reqBody
  );

  if (res.parsedBody && res.parsedBody.data.token) {
    return res.parsedBody.data.token;
  } else {
    throw withMalformedApiResponseError(
      "error fetching token",
      {
        url: `${process.env.HOST_ENV}/user/v1/user/sdk?token=true`,
        body: reqBody,
        method: "post",
      },
      res
    );
  }
};

/* HELPER */
const fetchBannerData = async (token: string): Promise<any> => {
  const res = await get(`${process.env.HOST_ENV}/reward/v1.1/user`, token);
  return res;
};

/* EXPORT #1 */
export const requestUserToken = async (
  data: IUserRegistrationData
): Promise<string> => {
  const token = await fetchToken(
    data.writeKey,
    data.userIdentification,
    data.userAttributes
  );
  const bannerData = await fetchBannerData(token);
  return token;
};
