import {
  CurrentPageProvider,
  IPageContent,
  IPageLayout,
} from "../../contexts/router";
import { useRouter } from "../../hooks/router";
import style from "./style.css";
import { Fragment, h } from "preact";
import { popPageAction } from "../../actions/router";
import { useEffect, useErrorBoundary, useRef } from "preact/hooks";
import { emitEventV2 } from "../../util/url";
import { useQueryParameter } from "../../hooks/session";
import ClientGuardWrapper from "../async/client-guard-wrapper";
import ErrorWrapper from "../async/error-wrapper";
import WalletWrapper from "../async/wallet-wrapper";
import RewardWrapper from "../async/reward-wrapper";
import ProgramWrapper from "../async/program-wrapper";
import ProgramNudgeWrapper from "../async/program-nudge-wrapper";
import FullPage from "../async/full-page";
import BottomSheet from "../async/bottom-sheet";
import Popup from "../async/popup";

export const Portal = () => {
  const [state, dispatch] = useRouter();
  let pageStack: JSX.Element[] = [];

  function pop() {
    dispatch(popPageAction());
  }

  if (state && state.allIds.length) {
    for (let i = state.allIds.length - 1; i >= 0; i--) {
      const id = state.allIds[i];
      let isActive = false;
      if (i == state.allIds.length - 1) {
        isActive = true;
      }
      pageStack.unshift(
        <CurrentPageProvider
          page={state.byId[id]}
          isActive={i === state.allIds.length - 1}
        >
          <PageFrame
            key={id}
            layout={state.byId[id].layout}
            startClose={state.byId[id].startClose}
            zIndex={1000 + i}
            onClose={pop}
            isActive={isActive}
          >
            <PageContent content={state.byId[id].content} />
            <ClientGuardWrapper />
          </PageFrame>
        </CurrentPageProvider>
      );
      if (state.byId[id].hidePreviousPages) break;
    }
  }

  return <Fragment>{pageStack}</Fragment>;
};

export const PageFrame = (props: {
  children: any;
  layout: IPageLayout;
  zIndex: number;
  startClose: boolean | undefined;
  isActive: boolean;
  onClose: () => void;
}) => {
  let fragment: JSX.Element | null = null;
  let isEmbed = useQueryParameter("isEmbedded");
  const isEmbedingEnabled = isEmbed === "true" ? true : false;
  const containerRef = useRef<HTMLDivElement>(null);
  let ro = null;
  useEffect(() => {
    if (isEmbedingEnabled && containerRef.current && props.layout.type_id === "FULL_PAGE" && props.isActive) {
      ro = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (
            entry.target.firstElementChild &&
            props.isActive &&
            isEmbedingEnabled
          ) {
            let contentHeight = entry.target.firstElementChild.clientHeight;

            postDimensionsUpdate(contentHeight);
          }
        }
      });
      ro.observe(containerRef.current);
      return () => {
        ro.disconnect();
      };
    }

  }, [containerRef, props.isActive]);

  function postDimensionsUpdate(height: number) {
    if (height > 100) {
      try {
        emitEventV2("DIMENSIONS_UPDATE", { height: height });
      } catch (e) {
        console.log(e);
      }
    }
  }
  if (props.layout.type_id === "FULL_PAGE") {
    fragment = (
      <FullPage
        isActive={props.isActive}
        onClose={props.onClose}
        startClose={props.startClose}
        {...props.layout}
      >
        {props.children}
      </FullPage>
    );
  } else if (props.layout.type_id === "BOTTOM_SHEET") {
    fragment = (
      <BottomSheet
        isActive={props.isActive}
        onClose={props.onClose}
        startClose={props.startClose}
        {...props.layout}
      >
        {props.children}
      </BottomSheet>
    );
  } else if (props.layout.type_id === "POPUP") {
    fragment = (
      <Popup
        isActive={props.isActive}
        onClose={props.onClose}
        startClose={props.startClose}
        {...props.layout}
      >
        {props.children}
      </Popup>
    );
  }
  if (fragment) {
    return (
      <div
        className={style.pageFrame}
        style={{
          zIndex: props.zIndex,
          height: `${isEmbedingEnabled ? "auto" : "100%"}`,
        }}
        ref={containerRef}
      >
        {fragment}
      </div>
    );
  } else {
    return (
      <ErrorWrapper
        status={{
          state: "LOAD_ERROR",
          message: "page type, not found",
        }}
      />
    );
  }
};

export const PageContent = (props: { content: IPageContent }) => {
  const [error, resetError] = useErrorBoundary();
  if (error) {
    return (
      <ErrorWrapper
        status={{
          state: "LOAD_ERROR",
          message: "error occurred, please try again later",
        }}
      />
    );
  }
  if (props.content.type_id === "WALLET_WRAPPER") {
    return <WalletWrapper {...props.content} />;
  } else if (props.content.type_id === "REWARD_WRAPPER") {
    return <RewardWrapper {...props.content} />;
  } else if (props.content.type_id === "PROGRAM_WRAPPER") {
    return <ProgramWrapper {...props.content} />;
  } else if (props.content.type_id === "PROGRAM_NUDGE_WRAPPER") {
    return <ProgramNudgeWrapper {...props.content} />;
  }
};
