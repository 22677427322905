import { createContext, h } from "preact";
import { useMemo, useReducer } from "preact/hooks";
import { IClientConfigurationsAction } from "../actions/client-configurations";
import {
  IAnalyticsConfig,
  IClientConfig,
  IThemeConfig,
} from "../interfaces/client-configurations";
import {
  IAllErrorFragments,
  IAllLoadingFragments,
  IAllWalletFragments,
} from "../interfaces/client-level-fragments";
import { IContext } from "../interfaces/store-action";
import { applyTheme } from "../util/apply-theme";
import { applyThemeConfig } from "../util/apply-theme-configurations";

export interface IClientConfigurationState {
  client: IClientConfig;
  wallet: IAllWalletFragments;
  loading: IAllLoadingFragments;
  error: IAllErrorFragments;
  theme: IThemeConfig;
  analytics: IAnalyticsConfig;
}

const initialConfigState: IClientConfigurationState = {
  client: null,
  wallet: null,
  error: null,
  loading: null,
  theme: null,
  analytics: null,
};

function configReducer(
  state: IClientConfigurationState,
  action: IClientConfigurationsAction
): IClientConfigurationState {
  switch (action.type) {
    case "UPDATE CLIENT CONFIGURATIONS": {
      if (action.payload?.theme) {
        try {
          applyThemeConfig(action.payload.theme);
          if(action.payload.wallet && action.payload.wallet.__HACKY__THEME) {
            applyTheme(action.payload.wallet.__HACKY__THEME, true);
          }
        } catch (e) {
          console.log(e);
        }
      }
      return { ...state, ...action.payload };
    }
  }
}

export const ClientConfigurationContext =
  createContext<
    IContext<IClientConfigurationState, IClientConfigurationsAction>
  >(undefined);

export function ClientConfigurationsProvider(props: { children?: any }) {
  const [state, dispatch] = useReducer<
    IClientConfigurationState,
    IClientConfigurationsAction
  >(configReducer, initialConfigState);
  const value = useMemo(() => ({ state, dispatch }), [state]);
  return (
    <ClientConfigurationContext.Provider value={value}>
      {props.children}
    </ClientConfigurationContext.Provider>
  );
}
