import { ICSS } from "src/interfaces/css";

export function getChildren<ITargetType>(
  properties: string[],
  map: { [key: string]: string[] },
  targetObject: ITargetType
) {
  try {
    const r = pluckValue(targetObject, properties);
    return map[r] ? map[r] : [];
  } catch (e) {
    console.log(e);
  }
}

export function getChildren2<ITargetType>(
  content: {
    properties: string[];
    map: {
      [key: string]: string[];
    };
    else?: string[];
  },
  targetObject: ITargetType
) {
  const r = pluckValue(targetObject, content.properties);
  if (content.map[r]) return content.map[r];
  else if (content.else) {
    return content.else;
  } else return [];
}

export function pluckValue(targetObject: any, properties: string[]) {
  if (!targetObject || !properties.length) return null;
  let r = targetObject;
  properties.forEach((p) => {
    if (r) r = r[p];
  });
  return r;
}

export interface IConditionalWrapperLayout {
  type_id: "CONDITIONAL_WRAPPER";
  ui: {
    content: {
      properties: string[];
      map: {
        [key: string]: string[];
      };
      else?: string[];
    };
    css: { container: ICSS };
  };
}
