import {
  ISessionAction,
  setQueryParamSession,
  setToken,
} from "../actions/session";
import { ISessionState, SessionContext } from "../contexts/session";
import { IDispatch } from "../interfaces/dispatch";
import { IStatus } from "../interfaces/status";
import { IUserRegistrationData } from "../interfaces/user-registration";
import { requestUserToken } from "../network/session";
import { getRegistrationDataFromObject } from "../util/get-registration-object";
import { getRouteQueryParams, IRouteQueryParams } from "../util/routes-details";
import { useContext, useEffect, useState } from "preact/hooks";
import { useStatus } from "./status";
import { withInternalError } from "../util/custom-errors";
import { useLogs } from "./logging";

export const useSession = (): [ISessionState, IDispatch<ISessionAction>] => {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw withInternalError("useSession must be used within a SessionProvider", "useSession");
  }
  return [context.state, context.dispatch];
};

export const useTokenInitilizer = (): IStatus => {
  const [status, setStatus] = useStatus();
  const [session, dispatchSessionAction] = useSession();
  const [recordError] = useLogs();

  useEffect(() => {
    try {
      const queryParams = getRouteQueryParams();
      if (queryParams.token) {
        dispatchSessionAction(setToken({ token: queryParams.token }));
        setStatus({ state: "LOAD_SUCCESS" });
      } else if (queryParams.writekey) {
        fetchUserToken(queryParams);
      } else {
        throw withInternalError("writekey not found", "useTokenInitilizer.useEffect");
      }
    } catch (e) {
      setStatus({ state: "LOAD_ERROR", message: "writekey not found" });
      recordError(e);
    }
  }, []);

  const fetchUserToken = async (queryParams: IRouteQueryParams) => {
    setStatus({ state: "LOADING" });
    try {
      const data: IUserRegistrationData =
        getRegistrationDataFromObject(queryParams);
      const token = await requestUserToken(data);
      dispatchSessionAction(setToken({ token }));
      setStatus({ state: "LOAD_SUCCESS" });
    } catch (e) {
      setStatus({
        state: "LOAD_ERROR",
        message:
          "error generating the token"
      });
      recordError(e);
    }
  };

  return status;
};

export const useQueryParamsInitilizer = () => {
  const [session, dispatchSessionAction] = useSession();

  useEffect(() => {
    const queryParams = getRouteQueryParams();
    window["skipSeen"] = queryParams["skipSeen"] === undefined ? false : true;
    dispatchSessionAction(setQueryParamSession({ queryParams }));
  }, []);
};

export const useQueryParameter = (key: string) => {
  const [session] = useSession();
  return session.queryParams ? session.queryParams[key] : null;
};
