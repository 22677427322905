import { IProgramActions } from "../actions/program";
import { IProgram } from "../interfaces";
import { IContext } from "../interfaces/store-action";
import { createContext, h } from "preact";
import { useMemo, useReducer } from "preact/hooks";

export interface IProgramsState {
  allIds: string[];
  byId: {
    [id: string]: IProgram;
  };
  allProgramsLoaded: boolean;
  referralLink: string;
}

const initialState: IProgramsState = {
  allIds: [],
  byId: {},
  allProgramsLoaded: false,
  referralLink: "**REFERRAL_LINK**",
};

function programsReducer(
  state: IProgramsState,
  action: IProgramActions
): IProgramsState {
  switch (action.type) {
    case "SET PROGRAMS": {
      const newProgramMap = action.payload.programMap;

      const newState: IProgramsState = {
        ...state,
        allIds: [...state.allIds],
        byId: { ...state.byId },
        allProgramsLoaded: action.payload.allProgramsLoaded,
      };

      for(let i = 0; i < newProgramMap.allIds.length; i++) {
        const id = newProgramMap.allIds[i];
        if(!state.byId[id]) {
          newState.allIds.push(id);
        }
        newState.byId[id] = newProgramMap.byId[id];
      }

      return newState;
    }
    case "SET PROGRAM": {
      const newState: IProgramsState = {
        ...state,
        byId: { ...state.byId },
      };

      if(!state.byId[action.payload.program.campaignId]) {
        newState.allIds = [...newState.allIds, action.payload.program.campaignId]
      }

      newState.byId[action.payload.program.campaignId] = {...action.payload.program}
      return newState;
    }
  }
}

export const ProgramsContext =
  createContext<IContext<IProgramsState, IProgramActions>>(undefined);

export function ProgramsProvider(props: { children?: any }) {
  const [state, dispatch] = useReducer<IProgramsState, IProgramActions>(
    programsReducer,
    initialState
  );

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <ProgramsContext.Provider value={value}>
      {props.children}
    </ProgramsContext.Provider>
  );
}
