import { IUserRegistrationData } from "../interfaces/user-registration";

export const getRegistrationDataFromObject = (matches: {
  [key: string]: string;
}): IUserRegistrationData => {
  const userId = matches["userId"];
  const anonymousId = matches["anonymousId"];
  const writeKey = matches["writeKey"] || matches["writekey"];

  const userName = matches["userName"];
  const phone = matches["userName"];
  const email = matches["userName"];
  const firebaseToken = matches["firebaseToken"];
  const apnsDeviceToken = matches["apnsDeviceToken"];
  const referredBy = matches["referredBy"];

  let platform = matches["platform"];
  if (platform) platform = platform.toLowerCase();
  let customAttributes = {};

  const gluAttributes = {
    userName,
    phone,
    email,
    firebaseToken,
    apnsDeviceToken,
    referredBy,
  };

  for (let key in matches) {
    if (
      !app[key] &&
      !gluAttributes[key] &&
      key.includes("_user_attribute_") &&
      matches[key]
    ) {
      customAttributes[key.split("_user_attribute_")[1]] = matches[key];
    }
  }

  return {
    writeKey,
    userIdentification: {
      anonymousId,
      userId,
    },
    userAttributes: {
      gluAttributes,
      customAttributes,
    },
  };
};
