import { IPageContent, IPageLayout } from "../../contexts/router";
import {
  useQueryParamsInitilizer,
  useTokenInitilizer,
} from "../../hooks/session";
import { useUserInitilizer } from "../../hooks/user";
import {
  IProgramFilter,
  IRewardFilter,
} from "../../interfaces/campaign-filter";
import { getRoutePath, getRouteQueryParams } from "../../util/routes-details";
import { Portal } from "../portal";
import { h } from "preact";
import { useEffect, useRef } from "preact/hooks";
import { useStatus } from "../../hooks/status";
import { useRouterActionHandler } from "../../hooks/router";
import { pushPageAction } from "../../actions/router";
import { useClientConfigurationsInitilizer } from "../../hooks/client-configurations";
import { useLogs } from "../../hooks/logging";
import { withInitilizationError } from "../../util/custom-errors";
import { useExternalDataSync } from "../../hooks/external-data-sync";
import ErrorWrapper from "../async/error-wrapper";
import LoadingWrapper from "../async/loading-wrapper";

export const Main = () => {
  const [status, setStatus] = useStatus();
  const [onRouterEvent] = useRouterActionHandler();
  const tokenStatus = useTokenInitilizer();
  const confgStatus = useClientConfigurationsInitilizer();
  useQueryParamsInitilizer();
  useUserInitilizer();
  const [recordError] = useLogs();
  const wrapperType = useRef<
    | "WALLET_WRAPPER"
    | "REWARD_WRAPPER"
    | "PROGRAM_WRAPPER"
    | "PROGRAM_NUDGE_WRAPPER"
  >(null);

  useExternalDataSync();

  useEffect(() => {
    if (tokenStatus.state === "LOAD_SUCCESS") {
      try {
        const layout = getLayout();
        const content = getPageContent();
        wrapperType.current = content.type_id;
        onRouterEvent(
          pushPageAction({
            layout: layout,
            content: content,
          })
        );
      } catch (e) {
        recordError(e);
        setStatus({
          state: "LOAD_ERROR",
          message: "error parsing root layout, content",
        });
      }
    }
  }, [tokenStatus]);

  if (tokenStatus.state === "LOAD_ERROR") {
    return <ErrorWrapper status={tokenStatus} />;
  } else if (status.state === "LOAD_ERROR") {
    return <ErrorWrapper status={status} />;
  } else if (confgStatus.state === "LOAD_ERROR") {
    return <ErrorWrapper status={confgStatus} />;
  }

  if (
    tokenStatus.state === "IDEL" ||
    tokenStatus.state === "LOADING" ||
    (wrapperType.current === "WALLET_WRAPPER" &&
      (confgStatus.state === "IDEL" || confgStatus.state === "LOADING"))
  ) {
    return <LoadingWrapper status={tokenStatus} />;
  }

  return <Portal></Portal>;
};

function getLayout() {
  const LAYOUT: IPageLayout = {
    type_id: "FULL_PAGE",
    ui: {
      content: {
        animation: false,
      },
      css: {
        container: {},
      },
    },
  };
  return LAYOUT;
}

type IWrapperType = "WALLET" | "REWARD" | "PROGRAM" | "PROGRAM_NUDGE";

function getWrapperType(): IWrapperType {
  const path = getRoutePath();
  try {
    const pathSplits = path.split("/");
    const firstPath = pathSplits[1];
    const dict: { [key: string]: IWrapperType } = {
      wallet: "WALLET",
      reward: "REWARD",
      program: "PROGRAM",
      "program-nudge": "PROGRAM_NUDGE",
    };
    if (dict[firstPath]) return dict[firstPath];
    else return "WALLET";
  } catch (e) {
    return "WALLET";
  }
}

function getPageContent(): IPageContent {
  const queryParams = getRouteQueryParams();
  const wrapperType = getWrapperType();
  const rootId = queryParams?.rootId;
  if (wrapperType === "WALLET") {
    return {
      type_id: "WALLET_WRAPPER",
      fragment: {
        rootId: rootId,
      },
      filter: {},
    };
  } else if (wrapperType === "REWARD") {
    const filter: IRewardFilter = {
      rewardUserId: {},
      campaignId: {},
    };
    if (queryParams.rewardUserId && filter.rewardUserId) {
      filter.rewardUserId[queryParams.rewardUserId] = true;
    } else if (queryParams.campaignId && filter.campaignId) {
      delete filter.rewardUserId;
      filter.campaignId[queryParams.campaignId] = true;
    }
    if (!filter.rewardUserId && !filter.campaignId) {
      throw withInitilizationError(
        "rewardUserId / campaignId not found in url"
      );
    }
    return {
      type_id: "REWARD_WRAPPER",
      filter,
      fragment: {
        rootId: rootId,
      },
    };
  } else if (wrapperType === "PROGRAM") {
    const filter: IProgramFilter = {
      campaignId: {},
    };
    if (queryParams.campaignId) {
      filter.campaignId[queryParams.campaignId] = true;
    } else {
      throw withInitilizationError("campaignId not found in url");
    }
    return {
      type_id: "PROGRAM_WRAPPER",
      filter,
      fragment: {
        rootId: rootId,
      },
    };
  } else if (wrapperType === "PROGRAM_NUDGE") {
    const filter: IProgramFilter = {
      campaignId: {},
    };
    if (queryParams.campaignId) {
      filter.campaignId[queryParams.campaignId] = true;
    } else {
      throw withInitilizationError("campaignId not found in url");
    }
    return {
      type_id: "PROGRAM_NUDGE_WRAPPER",
      filter,
      fragment: {
        rootId: rootId,
      },
    };
  }
}
