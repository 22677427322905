import { IRewardFilter } from "../interfaces/campaign-filter";
import { IGetPaginatedRewardMap, IGetPaginatedRewards, IGetRewards, IReward, IRewardMap } from "../interfaces";
import { post } from "../util/api";
import { withMalformedApiResponseError } from "../util/custom-errors";
import { sleep } from "../util/sleep";
import { getDummyPaginatedRewards } from "./mock/paginated-reward";

/* PARAM INTERFACE */
export interface IConsumeRewardParams {
  timeLeft?: number;
  selectedSlotIndex?: number;
  score?: number;
  generateNewReward?: boolean;
  createNewReward?: boolean;
  rewardUserId?: string;
  refreshReward?: boolean;
}

/* HELPER #1 */
export const fetchAllRewards = async (
  token: string,
  campaignId?: string,
  rewardUserId?: string
): Promise<IRewardMap> => {
  let url = `${process.env.HOST_ENV}/reward/v1.1/user/reward`;
  if (campaignId) {
    url = url + "?campaignId=" + campaignId;
  } else if (rewardUserId) {
    url = url + "?rewardUserId=" + rewardUserId;
  }
  let rewards: IRewardMap = null;
  if (process.env.MODE === "DEMO") {
    rewards = await (await import("./mock/reward")).default();
  } else {
    const res = await post<IGetRewards>(url, {}, token);
    const body = res.parsedBody;
    if (!body || !body.success || !body.data) {
      throw withMalformedApiResponseError(
        `invalid response, ${
          body && body["message"] ? "cause: " + body["message"] : null
        }`,
        { url, method: "post", body: {} },
        res
      );
    }
    rewards = body.data.rewards;
  }

  const currentDate = Math.floor(new Date().valueOf() / 1000);

  rewards.allIds = rewards.allIds.filter((id) => {
    const r = rewards.byId[id];
    if (r["fragmentMap"]) {
      r.layout = {
        campaignId: r.campaignId,
        layout: {
          ...r["fragmentMap"]["fragments"]["reward"],
          data: r["fragmentMap"]["data"],
        },
        programLayout: null,
        reportingVersion: r["fragmentMap"]["reportingVersion"],
        analyticsMeta: {
          campaignName:
            r["fragmentMap"]["analyticsMeta"] &&
            r["fragmentMap"]["analyticsMeta"] &&
            r["fragmentMap"]["analyticsMeta"]["campaignName"],
        },
        theme: r["fragmentMap"]["theme"]
      };
    }
    if (!r.layout?.layout) return false;
    if (r.experienceConsumed && r.slots) {
      const slotReward = r.slots[r.selectedSlotIndex].reward;
      if (slotReward.rewardProperties.couponExpiry?.expiry) {
        if (slotReward.rewardProperties.couponExpiry.expiry > currentDate)
          return true;
      }
      return true;
    } else {
      if (r.layout?.layout?.hidden) return false;
      else if (r.rewardExpiry?.expiry) {
        if (r.rewardExpiry.expiry > currentDate) return true;
        else return false;
      } else {
        return true;
      }
    }
  });
  return rewards;
};

/* HELPER #2 */
export const redeemReward = async (
  reward: IReward,
  params: IConsumeRewardParams,
  token: string,
  version: "v1.1" | "v2" = "v1.1"
): Promise<IReward> => {
  if (process.env.MODE === "DEMO" || window["skipSeen"]) {
    return await sleep({
      ...reward,
      seenStatus: "CONSUME_SUCCESS",
      status: "redeemable-seen",
    });
  } else {
    const url = `${process.env.HOST_ENV}/reward/${version}/user/reward/seen`;
    const res = await post<{ reward: IReward; success: boolean }>(
      url,
      { rewardUserId: reward.rewardUserId, ...params },
      token
    );
    const body = res.parsedBody;

    if (!body || !body.success || !body.reward) {
      throw withMalformedApiResponseError(
        "error redeeming reward",
        {
          url,
          body: { rewardUserId: reward.rewardUserId, ...params },
          method: "post",
        },
        res
      );
    }
    const seenReward = body.reward;
    const newReward: IReward = {
      ...reward,
      experienceConsumed: seenReward.experienceConsumed,
      rewardProperties: seenReward.rewardProperties,
      seenStatus: "CONSUME_SUCCESS",
      status: "redeemable-seen",
    };
    return newReward;
  }
};

export const fetchPaginatedRewards = async (
  filter: IRewardFilter,
  limit: number,
  page: number,
  sort?: {
    on: string;
    order: 1 | -1;
  }
): Promise<IGetPaginatedRewardMap> => {
  let url = `${process.env.HOST_ENV}/reward/v2/user/reward`;

  try {
    let rewards: IRewardMap = null;
    let totalPages: number = null;
    let currentPage: number = null;
    let totalItems: number = null;

    if (process.env.MODE === "DEMO") {
      let response = await getDummyPaginatedRewards(
        filter,
        3,
        page,
      );

      rewards = response.data.rewards;
      totalPages = response.totalPages;
      totalItems = response.totalItems;
      currentPage = response.page;
    } else {

      const res = await post<IGetPaginatedRewards>(
        url,
        { filter, sort, limit, page},
        window["GluToken"]
      );
      const body = res.parsedBody;
      if (!body || !body.success || !body.data) {
        throw `Invalid Response, ${
          body && body["message"] ? "cause: " + body["message"] : null
        }`;
      }

      rewards = body.data.rewards;
      totalPages = body.totalPages;
      currentPage = body.page;
      totalItems = body.totalItems;
    }

    const currentDate = Math.floor(new Date().valueOf() / 1000);
    if (rewards !== null) {

      //Razorpay block
if (typeof rewards.allIds !== 'undefined' && rewards.allIds.length > 0 ) {

  const rewardId = rewards.allIds[0];

  if(rewards.byId[rewardId].hasOwnProperty('layout')) {
    if(rewards.byId[rewardId].layout.hasOwnProperty('client')) {
  const client = rewards.byId[rewardId].layout.client;
if ( client == '2f5e1817-4783-4e4f-b63e-f29cb5629e52') {
    // console.log(rewards.byId[rewardId].layout)
    let campaignId = rewards.byId[rewardId].layout.campaignId;
    let token = await window["GluToken"];
      let rewardObj = await rewardInit(token, campaignId, rewardId);
}}}
}

      rewards.allIds = rewards.allIds.filter((id) => {
        const r = rewards.byId[id];
        if (r["fragmentMap"] && r["fragmentMap"]["fragments"]) {
          console.log(r.layout)
          r.layout = {
            campaignId: r.campaignId,
            layout: {
              ...r["fragmentMap"]["fragments"]["reward"],
              data: r["fragmentMap"]["data"],
            },
            programLayout: null,
            reportingVersion: r["fragmentMap"]["reportingVersion"],
            analyticsMeta: {
              campaignName:
                r["fragmentMap"]["analyticsMeta"] &&
                r["fragmentMap"]["analyticsMeta"] &&
                r["fragmentMap"]["analyticsMeta"]["campaignName"],
            },
            theme: r["fragmentMap"]["theme"]
          };
        }

        if (!r.layout?.layout) return false;
        else return true;
      });
    }

    return {
      totalPages: totalPages,
      page: currentPage,
      totalItems: totalItems,
      data: {
        rewards: rewards,
      },
      limit: limit,
    };
  } catch (e) {
    throw e;
  }
};

export const rewardInit = async (
  token: string,
  campaignId: string,
  rewardUserId: string
): Promise<IReward> => {
  const url = `${process.env.HOST_ENV}/reward/v2/user/reward/init`;

  try {
    let rewardObject = null;
    if (process.env.MODE === "DEMO") {
     rewardObject = await (await import("./mock/reward-init")).default();
    } else {
      const res = await post<IGetRewards>(
        url,
        { rewardUserId: rewardUserId, campaignId: campaignId },
        token
      );
      const body = res.parsedBody;
      if (!body || !body.success || !body.data) {
        throw `Invalid Response, ${
          body && body["message"] ? "cause: " + body["message"] : null
        }`;
      }
      rewardObject = body.data;
    }
    return rewardObject;
  } catch (e) {
    console.log(e);
  }
};

export const rewardShuffle = async (
  rewardUserId: string
): Promise<IReward> => {
  const url = `${process.env.HOST_ENV}/reward/v1.1/user/reward/shuffleReward`;

  try {
    let rewardObject: any = null;
    if (process.env.MODE === "DEMO") {
     rewardObject = await (await import("./mock/reward-init")).default();
    } else {
      const res = await post<{ reward: IReward, success: boolean }>(
        url,
        { rewardUserId: rewardUserId },
        window["GluToken"]
      );
      const body = res.parsedBody;
      if (!body || !body.success) {
        throw `Invalid Response, ${
          body && body["message"] ? "cause: " + body["message"] : null
        }`;
      }
      rewardObject = body.reward as IReward;
      rewardObject.isShuffled = true;
    }
    return rewardObject;
  } catch (e) {
    throw e;
  }
};