import { IUserAction, setUserAction } from "../actions/user";
import { IUserState, UserContext } from "../contexts/user";
import { IDispatch } from "../interfaces/dispatch";
import { IStatus } from "../interfaces/status";
import { fetchUser } from "../network/user";
import { useSession } from "./session";
import { useContext, useEffect } from "preact/hooks";
import { useStatus } from "./status";
import { withInternalError } from "../util/custom-errors";
import { useLogs } from "./logging";

export const useUser = (): [IUserState, IDispatch<IUserAction>] => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw withInternalError("useUser must be used within a SessionProvider", "useUser");
  }
  return [context.state, context.dispatch];
};

export const useUserInitilizer = (): [IStatus] => {
  const [status, setStatus] = useStatus();
  const [userState, userDispatch] = useUser();
  const [session] = useSession();
  const [recordError] = useLogs();

  useEffect(() => {
    if (session.token) {
      requestUser();
    }
  }, [session.token]);

  const requestUser = async () => {
    try {
      setStatus({ state: "LOADING" });
      if (!session.token) throw withInternalError("token not found", "useUserInitilizer.requestUser");
      const user = await fetchUser(session.token);
      userDispatch(setUserAction({ user }));
      setStatus({ state: "LOAD_SUCCESS" });
    } catch (e) {
      setStatus({ state: "LOAD_ERROR", message: "error fetching user"});
      recordError(e);
    }
  };

  return [status];
};
