import {
  IAnalyticsConfig,
  IClientConfig,
  IThemeConfig,
} from "../interfaces/client-configurations";
import { IAllErrorFragments, IAllLoadingFragments, IAllWalletFragments } from "../interfaces/client-level-fragments";
import { IAction } from "../interfaces/store-action";

type IUpdateClientConfigurations = IAction<
  "CLIENT CONFIGURATIONS",
  "UPDATE CLIENT CONFIGURATIONS",
  {
    client?: IClientConfig;
    wallet?: IAllWalletFragments;
    theme?: IThemeConfig;
    analytics?: IAnalyticsConfig;
    error?: IAllErrorFragments;
    loading?: IAllLoadingFragments;
  }
>;
export function updateClientConfigurations(payload: {
  client?: IClientConfig;
  wallet?: IAllWalletFragments;
  theme?: IThemeConfig;
  analytics?: IAnalyticsConfig;
  error?: IAllErrorFragments;
  loading?: IAllLoadingFragments;
}): IUpdateClientConfigurations {
  return {
    store: "CLIENT CONFIGURATIONS",
    type: "UPDATE CLIENT CONFIGURATIONS",
    payload,
  };
}

export type IClientConfigurationsAction = IUpdateClientConfigurations;
