import { useEffect, useRef } from "preact/hooks";
import { IPage } from "../contexts/router";
import { useCurrentPage, useRouter } from "./router";

export const useAutoRefresh = (callbacks: Function[]) => {
  const [currentPage] = useCurrentPage();
  const [router] = useRouter();
  const hidden = useRef(false);

  useEffect(() => {
    function autoRefresh() {
      try {
        if (document && document.visibilityState === "visible") {
          if (hidden.current === true) {
            for (let i = 0; i < callbacks.length; i++) {
              callbacks[i]();
            }
            hidden.current = false;
          }
        } else if (
          document &&
          document.visibilityState === "hidden" &&
          currentPage.isActive
        ) {
          hidden.current = true;
        }
      } catch (error) {}
    }

    if (window) {
      window.addEventListener("visibilitychange", autoRefresh, false);
      return () => window.removeEventListener("visibilitychange", autoRefresh);
    }
  }, []);

  useEffect(() => {
    if (currentPage.isActive && hidden.current === true) {
      for (let i = 0; i < callbacks.length; i++) {
        callbacks[i]();
      }
      hidden.current = false;
      return;
    }
    if (currentPage.isActive === false) {
      if (hidden.current === false) {
        const nextPage = router.byId[router.currentId];
        if (isPageTypeDifferent(currentPage.page, nextPage)) {
          hidden.current = true;
        }
      }
    }
  }, [currentPage.isActive]);
};

export function isPageTypeDifferent(page1: IPage, page2: IPage) {
  if (page1.content.type_id !== page2.content.type_id) return true;
  return false;
}
