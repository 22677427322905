import { useCommonState } from "./common-state";
import { useRouter } from "./router";

export const useLogs = (): [typeof recordError] => {
  const [commonDetails] = useCommonState();
  const [routerState] = useRouter();

  const recordError = async (e: unknown) => {
    if (!window["sendError"]) {
      window["sendError"] = (
        await import("../network/errors/transform")
      ).default;
    }
    window["sendError"](e, routerState, commonDetails);
  };

  return [recordError];
};
