import { IProgram, IReward } from "../interfaces";
import { AllPageNames } from "../interfaces/constants-mapper";
import { IActionHandler } from "../interfaces/event-actions";
import { useCommonState } from "./common-state";

interface IAnalyticsDetails {
  rootTypeId: string;
  rootId: string;
  reward?: IReward;
  program?: IProgram;
  pageName?: AllPageNames;
}

var pendingEvents: { reportingVersion: number; analyticsObj: {} }[] = [];

export function FlushPendingEvents() {
  while (pendingEvents.length > 0) {
    let pendingObj = pendingEvents.shift();
    if (pendingObj.analyticsObj["commonDetails"]) {
      pendingObj.analyticsObj["commonDetails"]["user"] = window["USER"];
    }
    delayRequest(pendingObj);
  }

  function delayRequest(obj) {
    console.log("flush pending objects ", obj);
    setTimeout(() => {
      if (obj.reportingVersion === 3) {
        sendV3Analytics(obj.analyticsObj);
      } else {
        sendV4Analytics(obj.analyticsObj);
      }
    }, 1000);
  }

  const sendV3Analytics = async (analyticObj) => {
    try {
      if (!window["v3Helper"]) {
        const v3Helper = (await import("../network/analytics-v3/transform"))
          .default;
        window["v3Helper"] = v3Helper;
      }
      window["v3Helper"](analyticObj);
    } catch (e) {
      console.log(e);
    }
  };

  const sendV4Analytics = async (analyticObj) => {
    try {
      if (!window["v4Helper"]) {
        const v4Helper = (await import("../network/analytics-v4/transform"))
          .default;
        window["v4Helper"] = v4Helper;
      }
      window["v4Helper"](analyticObj);
    } catch (e) {
      console.log(e);
    }
  };
}

export const useAnalytics = ({
  rootTypeId,
  rootId,
  pageName,
  reward,
  program,
}: IAnalyticsDetails): [IActionHandler] => {
  const [commonDetails] = useCommonState();
  let reportingVersion = 4;

  const onAction: IActionHandler = (action, details) => {
    if (process.env.MODE === "DEMO") return;

    if (window["user_is_set"]) {
      if (reportingVersion === 3) {
        sendV3Analytics(action, details);
      }
      if (reportingVersion === 4) {
        sendV4Analytics(action, details);
      }
    } else {
      if (reportingVersion === 3) {
        pendingEvents.push({
          reportingVersion: reportingVersion,
          analyticsObj: {
            rootId,
            rootTypeId,
            reward,
            program,
            action,
            stateDetails: details,
          },
        });
      } else {
        pendingEvents.push({
          reportingVersion: reportingVersion,
          analyticsObj: {
            action: action,
            details: details,
            rootTypeId: rootTypeId,
            pageName: pageName,
            rootId: rootId,
            reward: reward,
            program: program,
            commonDetails: commonDetails,
          },
        });
      }
    }
  };

  const sendV3Analytics: IActionHandler = async (action, details) => {
    try {
      if (!window["v3Helper"]) {
        const v3Helper = (await import("../network/analytics-v3/transform"))
          .default;
        window["v3Helper"] = v3Helper;
      }
      window["v3Helper"]({
        rootId,
        rootTypeId,
        reward,
        program,
        action,
        stateDetails: details,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const sendV4Analytics: IActionHandler = async (action, details) => {
    try {
      if (!window["v4Helper"]) {
        const v4Helper = (await import("../network/analytics-v4/transform"))
          .default;
        window["v4Helper"] = v4Helper;
      }
      window["v4Helper"]({
        action: action,
        details: details,
        rootTypeId: rootTypeId,
        pageName: pageName,
        rootId: rootId,
        reward: reward,
        program: program,
        commonDetails: commonDetails,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return [onAction];
};
