import { nanoid } from "nanoid";
import { useEffect } from "preact/hooks";
import { setRewardsAction } from "../actions/reward";
import {
  IBackendProgram,
  IGetPaginatedProgramMap,
  IGetPaginatedRewards,
  IProgramMap,
  IRewardMap,
} from "../interfaces";
import { formatProgram } from "../util/format-program";
import { getAppPlatform } from "../util/platform-details";
import { emitEventV2 } from "../util/url";
import { useRewards } from "./reward";

const initCallback = (callback: Function) => {
  const centralMessageReciver = (dataString: string) => {
    try {
      const event = dataString;
      callback(event);
    } catch (e) {
      console.log(e);
    }
  };

  window["sdkCallback"] = centralMessageReciver;
  if (window !== window.parent)
    window.addEventListener("message", (event) => {
      centralMessageReciver(event.data);
    });
};

export const useExternalDataSync = () => {
  const [rewardState, rewardDispatch] = useRewards();
  useEffect(() => {
    initCallback((event: any) => onEvent(event));
    const platform = getAppPlatform();
    if (
      platform === "flutter" ||
      platform === "android" ||
      platform === "reactNative" ||
      platform === "ios" ||
      window !== window.parent
    ) {
      loadCampaignsFromSdk();
    }
  }, []);

  const loadCampaignsFromSdk = async () => {
    const id = nanoid();
    emitEventV2("REQUEST_API_DATA", {
      requestId: id,
    });
  };

  const onEvent = (event: IIncomingEvent) => {
    if (!event || !event.eventName) return;
    try {
      if (event.eventName === "REQUEST_API_RESULT") {
        if (event.data && event.data.rewardsResponse) {
          const r = checkRewards(event.data.rewardsResponse.data.rewards);
          rewardDispatch(
            setRewardsAction({
              rewardMap: r,
            })
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

const checkRewards = (rewards: IRewardMap) => {
  if (rewards !== null) {
    rewards.allIds = rewards.allIds.filter((id) => {
      const r = rewards.byId[id];
      if (r["fragmentMap"] && r["fragmentMap"]["fragments"]) {
        r.layout = {
          campaignId: r.campaignId,
          layout: {
            ...r["fragmentMap"]["fragments"]["reward"],
            data: r["fragmentMap"]["data"],
          },
          programLayout: null,
          reportingVersion: r["fragmentMap"]["reportingVersion"],
          analyticsMeta: {
            campaignName:
              r["fragmentMap"]["analyticsMeta"] &&
              r["fragmentMap"]["analyticsMeta"] &&
              r["fragmentMap"]["analyticsMeta"]["campaignName"],
          },
          theme: r["fragmentMap"]["theme"],
        };
      }

      if (!r.layout?.layout) return false;
      else return true;
    });
  }
  return rewards;
};

const checkPrograms = (programs: IBackendProgram[]) => {
  let programsMap: IProgramMap = {
    allIds: [],
    byId: {},
  };
  if (programs !== null) {
    const now = new Date();
    const cleanedPrograms = [];

    programs.forEach((p) => {
      try {
        const formattedProgram = formatProgram(p);
        cleanedPrograms.push(formattedProgram);
      } catch (e) {
        console.log(e);
      }
    });

    cleanedPrograms.forEach((p) => {
      programsMap.allIds.push(p.campaignId);
      programsMap.byId[p.campaignId] = p;
    });
  }
  return programsMap;
};

interface IRequestApiResult {
  eventName: "REQUEST_API_RESULT";
  data: {
    requestId: string;
    rewardsResponse: IGetPaginatedRewards;
    programsResponse: IGetPaginatedProgramMap;
  };
}

type IIncomingEvent = IRequestApiResult;
