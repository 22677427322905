import { getSize } from "../hooks/component-content-style-resolver";
import { ICustomFont, IFont, IGoogleFont, ITheme } from "../interfaces/theme";
import { getRouteQueryParams } from "./routes-details";

const loadedFonts = {};

const loadGoogleFont = (font: IGoogleFont, head: HTMLHeadElement) => {
  if (loadedFonts[font.url]) return;
  let link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = font.url;
  head.append(link);
  loadedFonts[font.url] = true;
};

const loadCustomFont = (font: ICustomFont, head: HTMLHeadElement) => {
  let fontFaces = "";
  font.variants.forEach((v) => {
    if (loadedFonts[v.url]) return;
    const fontFace = `@font-face {
      font-family: '${font.family}';
      font-style: ${v.style};
      font-weight: ${v.weight};
      font-display: ${v.display || "swap"};
      src: url("${v.url}") format('woff2');
    }`;
    fontFaces += fontFace;
    loadedFonts[v.url] = true;
  });
  if (fontFaces) {
    const style = document.createElement("style");
    style.innerHTML = fontFaces;
    head.append(style);
  }
};

const loadFont = (font: IFont, head: HTMLHeadElement) => {
  if (!font) return;
  if (font.type === "GOOGLE") loadGoogleFont(font, head);
  else if (font.type === "CUSTOM") loadCustomFont(font, head);
};

export const applyTheme = (theme: ITheme, isGlobal?: boolean) => {
  if (!theme) return;
  let variables: {
    [key: string]: string;
  } = {};

  const q = getRouteQueryParams();
  let mode = q.mode;
  if (q.darkMode) {
    mode = q.darkMode === "true" ? "dark" : "light";
  }

  const head = document.getElementsByTagName("head")[0];

  if (theme.fonts) {
    if (theme.fonts.primaryFont) {
      const primary = theme.fonts.primaryFont;
      loadFont(primary, head);
      variables[primary.variable] = `"${primary.family}", sans-serif`;
    }
    if (theme.fonts.secondaryFont) {
      const secondary = theme.fonts.secondaryFont;
      loadFont(secondary, head);
      variables[secondary.variable] = `"${secondary.family}", sans-serif`;
    }
  }

  if (theme.deviceVariables) {
    const device = getSize();
    const deviceVariables = theme.deviceVariables[device];
    if (deviceVariables) {
      variables = {
        ...variables,
        ...deviceVariables,
      };
    }
  }

  if (theme.paletteVariables) {
    const paletteVariables = theme.paletteVariables[mode || "dark"];
    if (paletteVariables) {
      variables = {
        ...variables,
        ...paletteVariables.default,
        ...paletteVariables.custom,
      };
    }
  }

  if (theme.otherVariables) {
    variables = {
      ...variables,
      ...theme.otherVariables,
    };
  }

  if (isGlobal) {
    const style = document.createElement("style");
    let css = ":root {";
    for (const key in variables) {
      css += `${key}: ${variables[key]};`;
    }
    css += "}";
    style.innerHTML = css;
    head.append(style);
  }

  return variables;
};
