import { HttpResponse } from "./api";

interface IApiRequest {
  method: string;
  url?: string;
  body?: any;
}

export interface IErrorWithMeta extends Error {
  meta?:
    | IApiError
    | IMalformedApiResponseError
    | IInitilizationError
    | IInternalError;
}

export interface IApiError {
  name: "API_ERROR";
  severity: "EXCEPTION";
  statusCode: number;
  request: IApiRequest;
  response: any;
}

export interface IMalformedApiResponseError {
  name: "MALFORMED_API_RESPONSE_ERROR";
  severity: "EXCEPTION";
  request: IApiRequest;
  response: any;
}

export interface IInitilizationError {
  name: "INITILIZATION_ERROR";
  severity: "EXCEPTION";
}

export interface IInternalError {
  name: "INTERNAL_ERROR";
  severity: "EXCEPTION";
  functionName?: string | string[];
  functionArguments?: any;
}

export const withApiError = (
  e: Error | string | unknown,
  request: Request,
  response: HttpResponse<any>,
  body?: string
) => {
  const meta: IApiError = {
    name: "API_ERROR",
    severity: "EXCEPTION",
    statusCode: response?.status,
    request: {
      url: request.url,
      body: body,
      method: request.method,
    },
    response: response?.parsedBody,
  };

  const error = resolveError(e);
  error["meta"] = meta;
  return error;
};

export const withMalformedApiResponseError = (
  e: Error | string | unknown,
  request: IApiRequest,
  response: HttpResponse<any>
) => {
  const meta: IMalformedApiResponseError = {
    name: "MALFORMED_API_RESPONSE_ERROR",
    severity: "EXCEPTION",
    request: {
      url: request.url,
      body: request.body,
      method: request.method,
    },
    response: response.parsedBody,
  };

  const error = resolveError(e);
  error["meta"] = meta;
  return error;
};

export const withInitilizationError = (e: Error | string | unknown) => {
  const meta: IInitilizationError = {
    name: "INITILIZATION_ERROR",
    severity: "EXCEPTION",
  };
  const error = resolveError(e);
  error["meta"] = meta;
  return error;
};

export const withInternalError = (
  e: Error | string | unknown,
  functionName?: string | string[],
  functionArguments?: any
) => {
  const meta: IInternalError = {
    name: "INTERNAL_ERROR",
    severity: "EXCEPTION",
    functionName: functionName,
    functionArguments: functionArguments,
  };
  const error = resolveError(e);
  error["meta"] = meta;
  return error;
};

const resolveError = (e: unknown) => {
  let error: Error = null;
  if (e instanceof Error) {
    error = e;
  } else if (typeof e === "string") {
    error = new Error(e);
  } else {
    error = new Error(JSON.stringify(e));
  }

  return error;
};