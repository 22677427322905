import { ICSSBreakpoints, ICssPatch } from "../interfaces/css";
import { pluckValue } from "../util/pluck";

let SIZE: "mobile" | "tablet" | "laptop" = null;
const MAX_SIZE_BREAK_POINTS = {
  MOBILE: 480,
  TABLET: 800,
};

export const useComponentContentStyleResolver = (ui: {
  content: any;
  css: any;
  cssBreakpoints?: ICSSBreakpoints;
}) => {
  const { content, css, cssBreakpoints } = ui;

  try {
    if (cssBreakpoints && css && !css["__break_point_css_injected__"]) {
      const size = getSize();
      if (cssBreakpoints[size]) {
        const breakPointStyles: ICssPatch[] = cssBreakpoints[size];
        for (let i = 0; i < breakPointStyles.length; i++) {
          const bp = breakPointStyles[i];
          const nestedTarget = pluckValue(css, bp.key);
          Object.assign(nestedTarget, bp.patchValue);
          console.log("here");
        }
      }
      css["__break_point_css_injected__"] = true;
    }
  } catch (e) {
    console.log(e);
  }

  return { content, css };
};

export const getSize = () => {
  if (SIZE) return SIZE;
  SIZE = "mobile";
  if (window && window.innerWidth > MAX_SIZE_BREAK_POINTS.TABLET)
    SIZE = "laptop";
  else if (window && window.innerWidth > MAX_SIZE_BREAK_POINTS.MOBILE)
    SIZE = "tablet";
  return SIZE;
};
