import {
  CurrentPageContext,
  IPage,
  IRouterState,
  RouterContext,
} from "../contexts/router";
import { IDispatch } from "../interfaces/dispatch";
import { useContext } from "preact/hooks";
import { IRouterAction } from "../actions/router";
import { IActionHandler } from "../interfaces/event-actions";
import { getStateValues } from "../util/state-values";
import { withInternalError } from "../util/custom-errors";

export function useRouter(): [IRouterState, IDispatch<IRouterAction>] {
  const context = useContext(RouterContext);
  if (context === undefined) {
    throw withInternalError("useContext must be used within a RouterProvider", "useRouter");
  }
  return [context.state, context.dispatch];
}

export interface ICurrentPage {
  page: IPage;
  isActive: boolean;
}

export function useCurrentPage(): [ICurrentPage] {
  const context = useContext(CurrentPageContext);
  if (context === undefined) {
    return [null];
  }
  return [context];
}

export const useRouterActionHandler = (): [typeof onAction] => {
  const [state, dispatch] = useRouter();

  const onAction: IActionHandler = (action, details) => {
    if (action.store === "ROUTER") {
      try {
        if (action.payload && action.payload["page"]) {
          action.payload = action.payload["page"];
        }
        if(action.payloadStateMap && details?.state) {
          action.payload = {
            ...action.payload,
            ...getStateValues(details.state, action.payloadStateMap)
          }
        }
      } catch (e) {}
      dispatch(action);
      if (details && details.onComplete) {
        details.onComplete();
      }
    }
  };

  return [onAction];
};
