import { withApiError } from "./custom-errors";

export interface HttpResponse<T> extends Response {
  parsedBody?: T;
}

export async function http<T>(request: Request, body?: any): Promise<HttpResponse<T>> {
  let response: HttpResponse<T>;
  try {
    if(window["checkFromCache"] && window["checkFromCache"](request.url)) {
      response = await window["getFromCache"](request.url);
    }
    if(!response || !response.ok) {
      response = await fetch(request);
    }
    // may error if there is no body
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1)
      response.parsedBody = await response.json();
  } catch (e) {
    throw withApiError(e, request, response, body);
  }

  if (!response.ok) {
    throw withApiError(
      `API STATUS: ${response.status.toString()}`,
      request,
      response,
      body
    );
  }
  return response;
}

export async function post<T>(
  path: string,
  body: any,
  token?: string
): Promise<HttpResponse<T>> {
  const args: RequestInit = {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (token) {
    args.headers["authorization"] = `Bearer ${token}`;
  }
  return await http<T>(new Request(path, args), body);
}

export async function get<T>(
  path: string,
  token?: string
): Promise<HttpResponse<T>> {
  const args: RequestInit = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (token) {
    args.headers["authorization"] = `Bearer ${token}`;
  }
  return await http<T>(new Request(path, args));
}
