interface IStorage {
  savedAt: Date;
  expiresAt: Date;
  data: any;
  version?: any;
}

export const getFromLocalStorage = <T>(key: string): T => {
  try {
    if(!key) throw new Error("key is required");
    const storageDataString = localStorage.getItem(key);
    if (!storageDataString) return null;
    const storageData = JSON.parse(storageDataString) as IStorage;
    if (storageData.expiresAt) {
      const now = new Date();
      const exp = new Date(storageData.expiresAt);

      if (now.valueOf() > exp.valueOf()) return null;
    }
    return storageData.data;
  } catch (e) {
    return null;
  }
};

export const setToLocalStorage = (key: string, data: any, expiry?: number) => {
  try {
  if(!key) throw new Error("key is required");
    const now = new Date();
    let exp: Date = null;
    if (expiry) {
      exp = new Date();
      exp.setHours(exp.getHours() + expiry);
    }
    const storageData: IStorage = {
      savedAt: now,
      expiresAt: exp,
      data,
    };
    localStorage.setItem(key, JSON.stringify(storageData));
  } catch (e) {
    console.log(e);
  }
};

export const deleteLocalStorageKey = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.log(e);
  }
}