import { IClientConfigurationState } from "../contexts/client-configurations";
import { IConfigurationResponse } from "../interfaces/client-configurations";
import { get } from "../util/api";

export async function fetchConfigurations(token: string, config: string[]) {
  let end_point = `${process.env.HOST_ENV}/reward/v1.1/client/config?`;
  end_point = end_point + "config=";
  config.map((value) => {
    end_point = end_point + value + ",";
  });

  try {
    let body: IConfigurationResponse = null;
    if (process.env.MODE === "DEMO") {
      body = (await import("./mock/client-configurations")).default();
    } else {
      try {
        const res = await get<IConfigurationResponse>(end_point, token);
        body = res.parsedBody;
        if (!body || !body.success || !body.data) {
          throw `Invalid Response, ${
            body && body["message"] ? "cause: " + body["message"] : null
          }`;
        }
      } catch (e) {
        console.log(e);
        body = (await import("./mock/client-configurations")).default();
      }
    }

    const configData = body?.data;

    const data: IClientConfigurationState = {
      client: configData?.client,
      analytics: configData?.analytics,
      wallet: configData?.wallet,
      theme: configData?.client?.theme,
      loading: configData?.loading,
      error: configData?.error,
    };

    if (!data.wallet && config.includes("wallet")) {
      const mock = (await import("./mock/client-configurations")).default();
      data.wallet = mock.data.wallet;
    }

    return data;
  } catch (e) {
    throw e;
  }
}
