import { IPage } from "../contexts/router";
import { IStateMap, IStateMapGeneric } from "../interfaces/state-map";
import { IAction } from "../interfaces/store-action";

type IPushPageAction = IAction<
  "ROUTER",
  "ROUTER_STORE PUSH PAGE",
  IPage,
  IStateMapGeneric<"page">
>;
export function pushPageAction(payload: IPage): IPushPageAction {
  return {
    store: "ROUTER",
    type: "ROUTER_STORE PUSH PAGE",
    payload,
  };
}

type IPopPageAction = IAction<"ROUTER", "ROUTER_STORE POP PAGE", null>;
export function popPageAction(): IPopPageAction {
  return {
    store: "ROUTER",
    type: "ROUTER_STORE POP PAGE",
    payload: null,
  };
}

type IReplacePageAction = IAction<
  "ROUTER",
  "ROUTER_STORE REPLACE PAGE",
  IPage,
  IStateMapGeneric<"page">
>;
export function replacePageAction(payload: IPage): IReplacePageAction {
  return {
    store: "ROUTER",
    type: "ROUTER_STORE REPLACE PAGE",
    payload,
  };
}

type IPopPageStartAction = IAction<
  "ROUTER",
  "ROUTER_STORE POP PAGE START",
  { isNative?: boolean }
>;
export function popPageStartAction(payload: {
  isNative?: boolean;
}): IPopPageStartAction {
  return {
    store: "ROUTER",
    type: "ROUTER_STORE POP PAGE START",
    payload: payload,
  };
}

/* legacy push page */
interface ILegacyPage {
  props: {
    id: "REWARD_WRAPPER";
    data: {
      idType:
        | "REWARD_USER_ID"
        | "CAMPAIGN_ID"
        | "ACTIVTY_ID"
        | "EVENT_COMPLETED";
      rewardUserId?: string;
      campaignId?: string;
      activityId?: string;
      eventCompleted?: string;
    };
    listen?: any;
  };
  config: {
    mode: "FULL";
    animation: boolean;
    hideClose?: boolean;
    confetti?: boolean;
  };
}
type ILegacyPushPageAction = IAction<
  "ROUTER",
  "ROUTER_STORE PUSH PAGE",
  ILegacyPage
>;
export function legacyPushPageAction(
  payload: ILegacyPage
): ILegacyPushPageAction {
  return {
    store: "ROUTER",
    type: "ROUTER_STORE PUSH PAGE",
    payload,
  };
}

export type IRouterAction =
  | IPushPageAction
  | IPopPageAction
  | IReplacePageAction
  | IPopPageStartAction;
