import { IRewardState } from "../hooks/reward-state";
import { IStateMap } from "../interfaces/state-map";
import { withInternalError } from "./custom-errors";
import { getVariableValue } from "./state-variable-operation";

export function getStateValues<T>(state: any, stateMap: IStateMap): T {
  const stateToBeFilled: T = {} as T;

  if (!state) throw withInternalError("state is undefined", "getStateValues", { state, stateMap });

  if (stateMap && stateMap.select) {
    for (let key in stateMap.select) {
      let value = null;
      try {
        value = getVariableValue(state, stateMap.select[key]);
      } catch (e) {
        value = null;
        throw withInternalError(e, "getStateValues", { state, stateMap });
      }
      stateToBeFilled[key] = value;
    }
  }
  return stateToBeFilled; 
}

export function getStateValuesLegacy<T>(
  state: IRewardState,
  referenceId: string
): T {
  try {
    let elementState: T = {} as T;
    if (
      referenceId &&
      state &&
      state.layoutSlotInfo &&
      state.layoutSlotInfo.extraData &&
      state.layoutSlotInfo.extraData[referenceId]
    ) {
      elementState = state.layoutSlotInfo.extraData[referenceId].data as T;
    }

    return elementState;
  } catch (e) {
    console.log(e);
    throw withInternalError(e, "getStateValuesLegacy", { state, referenceId });
  }
}
