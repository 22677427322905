import { IRouterAction, popPageStartAction } from "../actions/router";
import { IBottomSheetLayout } from "../components/async/bottom-sheet";
import { IFullPageLayout } from "../components/async/full-page";
import { IPopupLayout } from "../components/async/popup";
import { emitEventV2 } from "../util/url";
import { createContext, h } from "preact";
import { useEffect, useMemo, useReducer } from "preact/hooks";
import { IWalletWrapper } from "../components/async/wallet-wrapper";
import { IRewardWrapper } from "../components/async/reward-wrapper";
import { IProgramWrapper } from "../components/async/program-wrapper";
import { IProgramNudgeWrapper } from "../components/async/program-nudge-wrapper";

export type IPageLayout = IFullPageLayout | IBottomSheetLayout | IPopupLayout;
export type IPageContent =
  | IWalletWrapper
  | IRewardWrapper
  | IProgramWrapper
  | IProgramNudgeWrapper;

export interface IPage {
  hidePreviousPages?: boolean;
  layout: IPageLayout;
  content: IPageContent;
  startClose?: boolean;
  state?: any;
}

export interface IRouterState {
  allIds: number[];
  byId: {
    [id: number]: IPage;
  };
  currentId: number;
}

const initialState: IRouterState = {
  allIds: [],
  byId: {},
  currentId: 0,
};

function routerReducer(
  state: IRouterState,
  action: IRouterAction
): IRouterState {
  switch (action.type) {
    case "ROUTER_STORE PUSH PAGE": {
      const actionPage = action.payload;
      if (state.allIds.length) {
        const previousSamePageIndex = state.allIds.findIndex((id, index) => {
          const page = state.byId[id];
          if (
            actionPage.content.type_id === "WALLET_WRAPPER" &&
            page.content.type_id === "WALLET_WRAPPER"
          )
            return true;
        });
        if (
          previousSamePageIndex !== -1 &&
          previousSamePageIndex !== state.allIds.length - 1
        ) {
          return {
            ...state,
            byId: {
              ...state.byId,
              [state.currentId]: {
                ...state.byId[state.currentId],
                startClose: true,
              },
            },
          };
        }
      }
      const newCurrentId = state.currentId + 1;
      if (history) history.pushState({ page: 1 }, newCurrentId.toString());
      return {
        ...state,
        allIds: [...state.allIds, newCurrentId],
        byId: {
          ...state.byId,
          [newCurrentId]: actionPage,
        },
        currentId: newCurrentId,
      };
    }
    case "ROUTER_STORE POP PAGE": {
      if (state.allIds.length === 1) {
        close();
        return state;
      } else
        return {
          ...state,
          allIds: state.allIds.filter((id) => id !== state.currentId),
          currentId: state.currentId - 1,
        };
    }
    case "ROUTER_STORE POP PAGE START": {
      if (!action.payload?.isNative) {
        if (history) history.back();
      }
      if (state.allIds.length === 1) {
       close();
        return state;
      } else
        return {
          ...state,
          byId: {
            ...state.byId,
            [state.currentId]: {
              ...state.byId[state.currentId],
              startClose: true,
            },
          },
        };
    }
    case "ROUTER_STORE REPLACE PAGE": {
      return {
        ...state,
        allIds: [...state.allIds],
        byId: {
          ...state.byId,
          [state.currentId]: action.payload,
        },
        currentId: state.currentId,
      };
    }
  }
}

export const RouterContext = createContext<
  { state: IRouterState; dispatch: (action: IRouterAction) => void } | undefined
>(undefined);

export function RouterProvider(props: { children?: any }) {
  const [state, dispatch] = useReducer<IRouterState, IRouterAction>(
    routerReducer,
    initialState
  );

  useEffect(() => {
    window.onpopstate = (e) => {
      dispatch(popPageStartAction({ isNative: true }));
    };
  }, []);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <RouterContext.Provider value={value}>
      {props.children}
    </RouterContext.Provider>
  );
}

export const CurrentPageContext = createContext<
  { page: IPage; isActive: boolean } | undefined
>(undefined);

export function CurrentPageProvider(props: {
  children?: any;
  page: IPage;
  isActive: boolean;
}) {
  return (
    <CurrentPageContext.Provider
      value={{ page: props.page, isActive: props.isActive }}
    >
      {props.children}
    </CurrentPageContext.Provider>
  );
}

function close() {
  emitEventV2("CLOSE", null);
  if (window.parent !== window) {
    const data: any = {
      target: "GLU_SDK",
      action: {
        type: "CLOSE_FRAME_CONTENT",
        data: {
          rewardsState: null,
          programsState: null,
        },
      },
    };
    window.parent.postMessage(data, "*");
  }
}
