import { IUser } from "../interfaces/user";
import { IRouteQueryParams } from "../util/routes-details";
import { ICurrentPage, useCurrentPage } from "./router";
import { useSession } from "./session";
import { useUser } from "./user";

export interface ICommonState {
  queryParams: IRouteQueryParams;
  user: IUser;
  currentPage: ICurrentPage;
  sessionId: string;
  session_start_time: Date;
}
export const useCommonState = (): [ICommonState] => {
  const [session] = useSession();
  const [user] = useUser();
  const [currentPage] = useCurrentPage();

  const state: ICommonState = {
    queryParams: session.queryParams,
    user: user.user,
    currentPage,
    sessionId: session.id,
    session_start_time: session.session_start_time,
  };

  return [state];
};
